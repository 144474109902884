import * as React from "react";
import { connect } from 'react-redux';
import {setNavBarStyle} from '../../redux/actions/distributor';
import { Loader } from './loader';

class Header extends React.Component<IProps,IState> {

  constructor(props:IProps){
	  super(props);
	  this.state = {
	  }
  }

  openNav=()=>{
	  this.props.setNavBarStyle({left:"0"})
  }
  logout = ($e:any)=>{
	  if($e.target.value === '2'){
           localStorage.clear();
		   window.location.href='/';
	  }
  }
  render() {
	const user_data = JSON.parse(localStorage.getItem('user_data') || '');
	const name = user_data.first_name + ' ' + user_data.last_name;
    return (
      <nav className="buuz_top_header">
	    <div className="toggle_nav d-block d-xl-none">
			<span onClick={this.openNav}>
				<img src="/images/menu_black.png" alt="" width="26" />
			</span>
		</div>
		<div className="top_header_heading">
			<h4>{this.props.header_title}</h4>
			<select className="form-control" onChange={this.logout}>
				<option defaultValue="1">{name}</option>
				<option value="2">Logout</option>
			</select>
		</div>
		{this.props.requestApiLoader && <Loader /> }
	</nav>
    );
  }
}

const mapDispatchToProps = (dispatch:any) => ({
	setNavBarStyle: (payload:{left:string}) => dispatch(setNavBarStyle(payload))
});

const mapStateToProps = ({requestApiLoader=false,header_title=''}) => {
	return { requestApiLoader,header_title };
  };
  
export default connect(mapStateToProps, mapDispatchToProps)(Header);
  

interface IProps{
    setNavBarStyle(payload:{left:string}):void;
	requestApiLoader : boolean;
	header_title : string
}

interface IState{
}

