import React from "react";
import { connect } from 'react-redux';
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Alert } from 'react-bootstrap';
import * as Yup from "yup";
import axios from "axios";
import constants from "../constants";
import { setApiLoader } from  '../redux/actions/distributor';

class ContactUs extends React.Component<IProps,IState>{
    constructor(props:IProps){
        super(props);
        this.state = {
            showAlert : false,
            variant : '',
            message : '',
        }
    }
    hideAlert = () =>{
        setTimeout(()=>{
            this.setState(prevState =>({
              showAlert : !prevState.showAlert
            }))
          },4000);
    }
    sendMessage = async(data:messageForm) =>{
        try {
            this.props.setApiLoader(); //set Api loader
            let url:string = `${constants.BASE_API_URL}user/contact_us`;
            const response = await axios.post(url,data,{headers:{ 'Authorization' : `Bearer ${localStorage.getItem('token')}` }});
            this.props.setApiLoader(); //set Api loader
            this.setState(prevState =>({
                showAlert: !prevState.showAlert,
                variant : 'success',
                message : response.data.message
            })); 

            this.hideAlert();
            
        } catch (error) {
            this.props.setApiLoader(); //set Api loader
            this.setState(prevState => ({
                showAlert: !prevState.showAlert,
                variant : 'danger',
                message : error.response.data.message
              }));
      
              this.hideAlert();
        }
    }
    render(){
        const validate = Yup.object({
             subject : Yup.string().required("Subject is required."),
             message : Yup.string().required("Message is required.")
        });
        const initialValues: messageForm = {
           subject : '',
           message : ''
        }
        return(
            <div className="buuz_right_outer">
                <div className="buuz_inner_heading">
                    <h1>Get in touch with us</h1>
                </div>
                <div className="buuz_connectus_wrapper">
                {this.state.showAlert && <Alert variant={this.state.variant}>{this.state.message}</Alert> }
                    <Formik initialValues={initialValues} validationSchema={validate}
                        onSubmit={(values, actions) => {
                            this.sendMessage(values);
                            actions.resetForm();
                        }}
                    >
                    <Form>
                        <div className="form-group">
                            <Field type="text" name="subject" className="form-control" placeholder="Subject" />
                            <ErrorMessage name="subject" component="span" className="text-danger"/>
                        </div>
                        <div className="form-group">
                            <Field as="textarea" name="message" className="form-control" rows={6} placeholder="Add Message"></Field>
                            <ErrorMessage name="message" component="span" className="text-danger"/>
                        </div>
                        <button type="submit" className="btn btn-primary">Send</button>
                    </Form>
                  </Formik>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state:any) => {
    return { state };
};

const mapDispatchToProps = (dispatch:any) => ({
    setApiLoader: () => dispatch(setApiLoader({}))
});

export default connect(mapStateToProps, mapDispatchToProps)(ContactUs);

interface IProps{
  setApiLoader():void;
}

interface IState{
    showAlert : boolean,
    variant : string,
    message : string
}

interface messageForm { 
   subject : string,
   message : string
}
