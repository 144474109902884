// Distributor Reducer

const distributorReducerDefaultState:IState = {
      left : '',
      requestApiLoader : false,
      user_data : '',
      header_title : 'Distributor Profile'
};

export default (state = distributorReducerDefaultState, action:any) => {
  switch (action.type) {
    case 'SET_NAVBAR_STYLE':
      return {
        ...state,
        left : action.payload.left
      }
    case 'SET_LOADER':
      return {
        ...state,
        requestApiLoader : !state.requestApiLoader
      }
    case 'SET_USER_DATA':
      return {
        ...state,
        user_data : action.payload.user_data
      }
    case 'SET_HEADER_TITLE':
      return {
        ...state,
        header_title : action.payload.header_title
      }
    default:
      return state;
  }
};

interface IState { 
  left : string;
  requestApiLoader : boolean;
  user_data : string;
  header_title : string;
}