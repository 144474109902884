import React from "react";
import { History } from 'history';
import { connect } from 'react-redux';
//import { Alert } from 'react-bootstrap';
import axios from "axios";
import constants from "../../constants";
import { setApiLoader } from  '../../redux/actions/distributor';
import ReactPaginate from 'react-paginate';

class LeadsOrders extends React.Component<IProps,IState> {

   constructor(props:IProps){
      super(props);
      this.state={
          orders:[],
          totalPages:1
      }
   }
   handlePageClick = (data:any) =>{
    let filter = {
      page: parseInt(data.selected)+1,
      limit: "10"
    };
    this.getSpecialRequest(filter);
   }

  componentDidMount = () => {
    let data = {
      page: "1",
      limit: "10",
    };
    this.getSpecialRequest(data);
  };
  getSpecialRequest = async (data: any) => {
    try {
      this.props.setApiLoader(); //set Api loader
      let url: string = `${constants.BASE_API_URL}orders/get_special_request_orders?page=${data.page}&limit=${data.limit}`;
      const response = await axios.get(url, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      });
      this.props.setApiLoader(); //set Api loader
      this.setState({
          orders : [...response.data.data.orders],
          totalPages : response.data.data.totalPages
      })

      //console.log(this.state.orders);
      //console.log(JSON.parse(response.data.data.orders[0].json));
    } catch (error) {
      this.props.setApiLoader(); //set Api loader
    }
  };

   render(){
     return (
        <div className="buuz_right_outer">
          {/* <div className="buuz_inner_heading">
                      <h1>MARA Imports</h1>
                  </div> */}
          <div className="portfolio_wrapper">
            {/* {this.state.showAlert && <Alert variant={this.state.variant}>{this.state.message}</Alert> } */}
  
            <div className="tab-content">
              <div id="all" className="tab-pane active">
                <div className="table-responsive portfolio_table">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Account Name</th>
                        <th>Email</th>
                        <th>Phone</th>
                        <th>Special Name</th>
                        <th>Image</th>
                        {/* <th className="profolio_dis_td" style={{ width: "300" }}>
                          Brand
                        </th> */}
                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.orders.length > 0 &&
                        this.state.orders.map((data: any) => {
                        const json = JSON.parse(data.json);
                          return(
                          <tr key={data.id}>
                            <td>{data.owner_name}</td>
                            <td>{data.email}</td>
                            <td>{data.phone}</td>
                            <td>{json.special_name}</td>
                            <td>
                              {json.category === "1" &&  <img src={(json.logo != '') ?  'https://buuzapp.com/upload/wine_orig/'+json.logo : json.main_image} alt="brand img" width="70"/>}
                              {json.category === "2" && <img src={(json.logo != '') ? 'https://buuzapp.com/upload/liquor_orig/'+json.logo : json.main_image} alt="brand img" width="70"/>}
                              {json.category === "3" && <img src={(json.logo != '') ? 'https://buuzapp.com/upload/beer_orig/'+json.logo : json.main_image} alt="brand img" width="70"/>}
                              {json.category === "4" && <img src={(json.image) ? json.image : '/images/logo.png' } alt="brand img" width="70"/>}
                            </td>
                            {/* <td className="profolio_dis_td" width={300}>
                              <p>{json.brand}</p>
                            </td> */}
                            <td className="status_btn">
                              {data.status === 0 && 'New Lead'}
                              {data.status === 1 && 'Lead In-Process'}
                              {data.status === 2 && 'Lead Processed'}
                              {data.status === 3 && 'Lead Closed'}
                            </td>
                            <td className="action_btn">
                              <div className="d-flex">
                                <span className="portfolio_edit" onClick={()=>this.props.history.push(`/leads_details/${data.id}`)}>
                                  <i className="fa fa-pencil" aria-hidden="true"></i>
                                </span>
                              </div>
                            </td>
                          </tr>
                        )})}
                        {this.state.orders.length === 0 && <tr><td align="center" colSpan={7}>No data found.</td></tr>}
                    </tbody>
                  </table>
                </div>
                <div
                  className="mt-4 text-right d-flex"
                  style={{ justifyContent: "flex-end" }}
                >
                  <ReactPaginate
                    previousLabel={"previous"}
                    nextLabel={"next"}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={this.state.totalPages}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={this.handlePageClick}
                    containerClassName={"pagination"}
                    activeClassName={"active"}
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      );
   }
}

const mapStateToProps = (state: any) => {
    return { state };
};
  
const mapDispatchToProps = (dispatch: any) => ({
    setApiLoader: () => dispatch(setApiLoader({})),
});

export default connect(mapStateToProps, mapDispatchToProps)(LeadsOrders);

interface IProps{
    history: History;
    setApiLoader(): void;
}

interface IState{
    orders : any,
    totalPages: number;
}