import React from "react";
import { History } from 'history';
import { connect } from 'react-redux';
import { Formik, Form, Field, ErrorMessage } from "formik";
//import { Alert } from 'react-bootstrap';
import axios from "axios";
import constants from "../../constants";
import { setApiLoader } from  '../../redux/actions/distributor';
import ReactPaginate from 'react-paginate';
import { Modal } from 'react-bootstrap';
import * as Yup from "yup";

class BeerList extends React.Component<IProps,IState>{
    constructor(props:IProps){
        super(props);
        this.state = {
            showAlert : false,
            variant : '',
            message : '',
            products : [],
            activeTab : '2',
            totalPages : 1,
            searchText : '',
            buttonState : false,
            ModelShow:false,
            selected_portfolio_product:''
        }
    }
    addPortfolio = () =>{
        this.props.history.push('/add_portfolio');
    }
    hideAlert = () =>{
        setTimeout(()=>{
            this.setState(prevState =>({
              showAlert : !prevState.showAlert
            }))
          },4000);
    }
    componentDidMount = () =>{
        let data = {
            page : '1',
            limit : '10',
            category : '2',
            search : ''
        }
        this.getProducts(data);
    }
    getProducts = async(data:any) =>{
        try {
            this.props.setApiLoader(); //set Api loader
            let url:string = `${constants.BASE_API_URL}protfolio/get_beer?page=${data.page}&limit=${data.limit}&category=${data.category}&search=${data.search}`;
            const response = await axios.get(url,{headers:{ 'Authorization' : `Bearer ${localStorage.getItem('token')}` }});
            this.props.setApiLoader(); //set Api loader
            this.setState(prevState =>({
                showAlert: !prevState.showAlert,
                variant : 'success',
                message : response.data.message,
                products : [...response.data.data.products],
                totalPages : response.data.data.totalPages,
                buttonState : false
            })); 

            //console.log(response.data.data)

            this.hideAlert();
            
        } catch (error) {
            this.props.setApiLoader(); //set Api loader
            this.setState(prevState => ({
                showAlert: !prevState.showAlert,
                variant : 'danger',
                message : error.response.data.message,
                buttonState  : false
              }));
      
              this.hideAlert();
        }
    }
    handleCLick = (val:string) =>{
        this.setState((prevState)=>({
            activeTab : val,
            searchText : ''
        }));
        let data = {
            page : '1',
            limit : '10',
            category : val,
            search : this.state.searchText
        }
        this.getProducts(data);
    }
    handlePageClick = (data:any) =>{
        //console.log(data)
      let filter = {
        page : data.selected+1,
        limit : '10',
        category : this.state.activeTab,
        search : this.state.searchText
      }
      this.getProducts(filter);
    }
    searchProducts = ($event:any) =>{
       this.setState({searchText:$event.target.value,buttonState:true},()=>{
            let filter = {
                page : '1',
                limit : '10',
                category : this.state.activeTab,
                search : this.state.searchText
            }
            this.getProducts(filter);
       });

    }
    handleClose = () => {
        this.setState({ModelShow:false})
    }
    handleShow = () => {
        this.setState({ModelShow:true})
    };
    handle_selected_product = (data:any)=>{
        this.setState({selected_portfolio_product:JSON.stringify(data)});
        this.handleShow();
    }
    addProduct = async(values:productForm)=>{
      try{
        let val:any = JSON.parse(this.state.selected_portfolio_product);
        let subform;
        if(this.state.activeTab === '1'){
            subform = {
                category:val.wine_type,
                name : val.wine_name,
                brand : 'wine',
                description : val.wine_desc,
                country : 'USA',
                quantity : (val.size) ? val.size : '1',
                bottle_price:values.bottle_price,
                case_price:values.case_price,
                image_1 : val.wine_image_1,
                brand_id : val.wine_id
            }
        }
        if(this.state.activeTab === '2'){
            subform = {
                category:val.type,
                name : val.liquor_title,
                brand : 'liquor',
                description : val.liquor_description,
                country : 'USA',
                quantity : (val.size) ? val.size : '1',
                bottle_price:values.bottle_price,
                case_price:values.case_price,
                image_1 : val.liquor_image,
                brand_id : val.liquor_id
            }
        }
        if(this.state.activeTab === '3'){
            subform = {
                category:val.beer_type,
                name : val.beer_name,
                brand : 'beer',
                description : val.beer_desc,
                country : 'USA',
                quantity : (val.size) ? val.size : '1',
                bottle_price:values.bottle_price,
                case_price:values.case_price,
                image_1 : val.beer_image,
                brand_id : val.beer_id
            }
        }
        this.props.setApiLoader(); //set Api loader
        this.handleClose();
        let url:string = `${constants.BASE_API_URL}protfolio/add_potfolio_product`;
        const response = await axios.post(url,subform,{headers:{ 'Authorization' : `Bearer ${localStorage.getItem('token')}` }});
        this.props.setApiLoader(); //set Api loader
        this.setState(prevState =>({
            showAlert: !prevState.showAlert,
            variant : 'success',
            message : response.data.message,
            ModelShow : false
        })); 
        this.hideAlert();
        this.props.history.push('/portfolio');
    } catch (error) {
        this.props.setApiLoader(); //set Api loader
        this.setState(prevState => ({
            showAlert: !prevState.showAlert,
            variant : 'danger',
            message : error.response.data.message,
            ModelShow : false
          }));
        this.hideAlert();
     }
    }
    render(){
        const validate = Yup.object({
            bottle_price : Yup.string().matches(/^[0-9]\d*(\.\d+)?$/, "Bottle price is not valid.").required("Bottle price is required."),
            case_price : Yup.string().matches(/^[0-9]\d*(\.\d+)?$/, "Case price is not valid.").required("Case price is required.")
        });
        const initialValues : productForm = {
            bottle_price : '',
            case_price : ''
        }
        return(
            <div className="buuz_right_outer">
                <div className="buuz_inner_heading">
                    <h1>ADD PRODUCTS TO PORTFOLIO</h1>
                </div>
                <div className="portfolio_wrapper">
                   {/* {this.state.showAlert && <Alert variant={this.state.variant}>{this.state.message}</Alert> } */}
                    <ul className="nav nav-tabs align-items-center border-0 mb-0">
                        <h4 className="mr-2">Select Database:</h4>
                        <li><a onClick={()=>this.handleCLick('2')} className={this.state.activeTab === "2" ?  "active" : ""} data-toggle="tab" href="#all">ADD LIQUOR</a></li>
                        <li><a onClick={()=>this.handleCLick('1')} data-toggle="tab" className={this.state.activeTab === "1" ?  "active" : ""}>ADD WINE</a></li>
                        <li><a onClick={()=>this.handleCLick('3')} data-toggle="tab" className={this.state.activeTab === "3" ?  "active" : ""}>ADD BEER</a></li>
                    </ul>
                    <div className="portfolio_search align-items-center ">
                        <form>
                            <div className="form-group">
                                <input type="text" className="form-control" name="search" placeholder="Search Products" value={this.state.searchText} onChange={this.searchProducts}  />
                            </div>
                        </form>
                    </div>
                    <div className="tab-content">
                    <div id="all" className="tab-pane active">
                    <h5 className="mb-3 mt-4">Search Result:</h5>
                        <div className="table-responsive portfolio_table">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Type</th>
                                        <th>Image</th>
                                        <th>Brand</th>
                                        <th className="profolio_dis_td" style={{width:"300"}}>Product Description</th>
                                        {/* <th>Container</th> */}
                                        <th>Unit Size</th>
                                        {/* <th>ABV</th> */}
                                        <th>Status</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {/* {this.state.products.length > 0 */}
                                    { this.state.activeTab === '2' && this.state.products.map((data:any,index:any)=>(                                        
                                        <tr key={index}>
                                            <td className="name_td"><p>{data.liquor_title}</p></td>
                                            <td>{data.type}</td>
                                            <td><img src={(data.liquor_image !== '') ? 'https://buuzapp.com/upload/liquor_orig/'+data.liquor_image : '/images/logo.png' } alt="brand img" width="70" /></td>
                                            <td>Liquor</td>
                                            <td className="profolio_dis_td"  width="300"><p>{data.liquor_description}</p></td>
                                            {/* <td>Container</td> */}
                                            <td>{data.size}</td>
                                            {/* <td>{data.abv}</td> */}
                                            <td>{data.status}</td>
                                            <td><button type="button" className="btn btn-primary text-nowrap px-4" onClick={()=>this.handle_selected_product(data)}>Add New</button></td>
                                        </tr>
                                    ))}
                                    { this.state.activeTab == '1' && this.state.products.map((data:any,index:any)=>(                                        
                                        <tr key={index}>
                                            <td className="name_td"><p>{data.wine_name}</p></td>
                                            <td>{data.wine_type}</td>
                                            <td><img src={(data.wine_image_1 !== '') ? 'https://buuzapp.com/upload/wine_orig/'+data.wine_image_1 : '/images/logo.png'} alt="brand img" width="70" /></td>
                                            <td>Wine</td>
                                            <td className="profolio_dis_td"  width="300"><p>{data.wine_desc}</p></td>
                                            {/* <td>container</td> */}
                                            <td>{data.size}</td>
                                            {/* <td>{data.abv}</td> */}
                                            <td>{data.status}</td>
                                            <td><button type="button" className="btn btn-primary text-nowrap px-4" onClick={()=>this.handle_selected_product(data)}>Add New</button></td>
                                        </tr>
                                    ))}
                                    { this.state.activeTab === '3' && this.state.products.map((data:any,index:any)=>(                                        
                                        <tr key={index}>
                                            <td className="name_td"><p>{data.beer_name}</p></td>
                                            <td>{data.beer_type}</td>
                                            <td><img src={(data.beer_image !== '') ? 'https://buuzapp.com/upload/beer_orig/'+data.beer_image : '/images/logo.png'} alt="brand img" width="70" /></td>
                                            <td>Beer</td>
                                            <td className="profolio_dis_td"  width="300"><p>{data.beer_desc}</p></td>
                                            {/* <td>Conatiner</td> */}
                                            <td>{data.size}</td>
                                            {/* <td>{data.abv}</td> */}
                                            <td>{data.status}</td>
                                            <td><button type="button" className="btn btn-primary text-nowrap px-4" onClick={()=>this.handle_selected_product(data)}>Add New</button></td>
                                        </tr>
                                    ))} 
                                  {/* }                                   */}
                                </tbody>
                            </table>
                         </div>
                        <div className="mt-4 text-right d-flex" style={{justifyContent:'flex-end'}}>
                            <ReactPaginate
                                previousLabel={'previous'}
                                nextLabel={'next'}
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={this.state.totalPages}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={3}
                                onPageChange={this.handlePageClick}
                                containerClassName={'pagination'}
                                activeClassName={'active'}
                                pageClassName={'page-item'}
                                pageLinkClassName={'page-link'}
                                previousClassName={'page-item'}
                                previousLinkClassName={'page-link'}
                                nextClassName={'page-item'}
                                nextLinkClassName={'page-link'}
                             />     
                        </div>
                       </div>
                    </div>
                </div>
                    <Modal
                        show={this.state.ModelShow}
                        onHide={this.handleClose}
                        backdrop="static"
                        keyboard={false}
                        animation={false}
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>Porduct Details</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Formik initialValues={initialValues} validationSchema={validate}
                                onSubmit={(values, actions) => {
                                this.addProduct(values);
                                actions.resetForm();
                            }} >
                             <Form>
                                <div className="form-group with_icon_field">
                                    <Field type="text" className="form-control" name="bottle_price" placeholder="Bottle Price"/>
                                    <div className="form_control_icon">
                                        <img src="/images/dollar.svg" alt="" />
                                    </div>
                                    <ErrorMessage name="bottle_price" component="span" className="text-danger" />
                                </div>
                                <div className="form-group with_icon_field">
                                    <Field type="text" className="form-control" name="case_price" placeholder="Case Price"/>
                                    <div className="form_control_icon">
                                        <img src="/images/dollar.svg" alt="" />
                                    </div>
                                    <ErrorMessage name="case_price" component="span" className="text-danger" />
                                </div>
                                <div className="custom-control custom-checkbox my-4">
                                    <Field type="checkbox" className="custom-control-input" id="customControlValidation1" />
                                    <label className="custom-control-label" htmlFor="customControlValidation1">Rainbow/Mix Case Available</label>
                                </div>
                                <div className="text-center">
                                    <button type="submit" className="btn btn-primary text-uppercase"><i className="fa fa-plus mr-1" aria-hidden="true"></i> Add</button>
                                </div>
                              </Form>
                             </Formik>
                        </Modal.Body>
                        {/* <Modal.Footer>
                            <Button variant="secondary" onClick={this.handleClose}>Close</Button>
                            <Button variant="primary">Understood</Button>
                        </Modal.Footer> */}
                </Modal>
            </div>
        )
    }
}

const mapStateToProps = (state:any) => {
    return { state };
};

const mapDispatchToProps = (dispatch:any) => ({
    setApiLoader: () => dispatch(setApiLoader({}))
});

export default connect(mapStateToProps, mapDispatchToProps)(BeerList);


interface IProps {
    history:History;
    setApiLoader():void;
}
  
interface IState {
    showAlert : boolean,
     variant : string,
     message : string,
     products : any,
     activeTab : string,
     totalPages : number,
     searchText : string;
     buttonState : boolean;
     ModelShow:boolean;
     selected_portfolio_product:string;
}

interface productForm{
    bottle_price : string;
    case_price : string;
}