import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';

const PublicRoute = (props: PublicRouteProps) => {
    let { component: Component, ...rest } = props;
    return (
        <Route
            {...rest}
            render={(props) => {
                return localStorage.getItem('token') ? <Redirect to="/dashboard" /> : <Component {...props} />;
            }}
        />
    );
  };
  
  export default PublicRoute;
  
  interface PublicRouteProps extends RouteProps {
      component: React.ComponentType<any>;
  }