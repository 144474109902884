import React from "react";
import { History } from "history";
import { connect } from "react-redux";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Alert } from "react-bootstrap";
import axios from "axios";
import constants from "../../constants";
import { setApiLoader } from "../../redux/actions/distributor";
import Select from "react-select";
import moment from "moment";

class AddSpecial extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      showAlert: false,
      variant: "",
      message: "",
      brand_name_array: [],
      brand_images_array: [],
      brand: "",
      states:[],
      prices : {
        case_price: "",
        bottle_price: "",
        id:''
      }
    };
  }
  hideAlert = () => {
    setTimeout(() => {
      this.setState((prevState) => ({
        showAlert: !prevState.showAlert,
      }));
    }, 4000);
  };
  componentDidMount = async() => {
    try{
      this.props.setApiLoader(); //set Api loader
      let url: string = `${constants.BASE_API_URL}protfolio/get_states`;
      const response = await axios.get(url, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      });
      this.props.setApiLoader(); //set Api loader
      let state_array:Array<state_details> = [];
      response.data.data.forEach((data:any)=>{
          state_array.push({
            value: data.state_id, label: data.state_name
          });
      });
      this.setState({
        states : state_array
      });
      //console.log(this.state.states)
    } catch (error) {
      this.props.setApiLoader(); //set Api loader
      this.setState((prevState) => ({
        showAlert: !prevState.showAlert,
        variant: "danger",
        message: error.response.data.message,
      }));
      this.hideAlert();
    }
  }

  addProduct = async (val: productForm) => {
    try {
      let formdata = new FormData();
      let stateString:any = [];
      val.state.forEach((data:state_details)=>{
          stateString.push(data.value)
      });
      stateString = stateString.join();
      let category:string;
      if(val.category === 'wine'){
        category = '1';
      }else if(val.category === 'liquor'){
        category = '2';
      }else if(val.category === 'beer'){
        category = '3';
      }else{
        category = '4';
      }
      const created_at= moment().format("YYYY-MM-DD  hh:mm:ss");
      formdata.append("category", category);
      formdata.append("country", val.country);
      formdata.append("description", val.description);
      formdata.append("name", val.name);
      formdata.append("state", stateString);
      formdata.append("brand", this.state.brand);
      formdata.append("start_date", val.start_date);
      formdata.append("end_date", val.end_date);
      formdata.append("bottle", val.bottle);
      formdata.append("size", val.size);
      formdata.append("regular_price", val.regular_price);
      formdata.append("special_price", val.special_price);
      formdata.append("discount", val.discount);
      formdata.append("status", val.status);
      formdata.append("created_at", created_at);
      formdata.append("image_1", val.image_1);
      formdata.append("brand_images", JSON.stringify(this.state.brand_images_array));
      formdata.append("portfolio_id", this.state.prices.id);

      this.props.setApiLoader(); //set Api loader
      let url: string = `${constants.BASE_API_URL}protfolio/add_special_release`;
      const response = await axios.post(url, formdata, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      });
      this.props.setApiLoader(); //set Api loader
      this.setState((prevState) => ({
        showAlert: !prevState.showAlert,
        variant: "success",
        message: response.data.message,
      }));
      this.hideAlert();
      this.props.history.push("/special");
    } catch (error) {
      this.props.setApiLoader(); //set Api loader
      this.setState((prevState) => ({
        showAlert: !prevState.showAlert,
        variant: "danger",
        message: error.response.data.message,
      }));
      this.hideAlert();
    }
  };
  getBrand = async (category: string, setfieldValue: any, $e: any) => {
    try {
      this.setState({
        brand: $e.target.value,
      });
      setfieldValue("brand", $e.target.value);
      if (category === "" || category === "Other Special") return false;
      this.props.setApiLoader(); //set Api loader
      let val = {
        category,
        brand: $e.target.value,
      };
      let url: string = `${constants.BASE_API_URL}protfolio/get_brands`;
      const response = await axios.post(url, val, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      });
      this.props.setApiLoader(); //set Api loader
      //set product if there is no product found in the database
      const product = [{
        name:"Not found.",
        quantity:1,
        case_price:'',
        bottle_price:'',
        image_1:'',
        image_2:'',
        brand:'',
        id:''
      }];
      this.setState({
        brand_name_array: (response.data.data.products.length > 0) ? response.data.data.products : product,
      });
      //console.log(response.data);
    } catch (error) {
      this.props.setApiLoader(); //set Api loader
      this.setState({
        brand_name_array: [{
            name:"Not found.",
            quantity:1,
            case_price:'',
            bottle_price:'',
            image_1:'',
            image_2:'',
            brand:'',
            id:''
          }],
      });
    }
  };
  handleClick = (product: product, category: string) => {
    let images:any = [];
    images.push(product.image_1);
    images.push(product.image_2);
    this.setState((prevState)=>(
      {
        brand:product.name,
        brand_name_array: [],
        brand_images_array : [...images],
        prices:{
          ...prevState.prices,
          case_price : product.case_price,
          bottle_price : product.bottle_price,
          id : product.id
        }
      }));
  };
  calculateDiscount = (values: productForm, setFieldValue: any) => {
    if (values.special_price !== "" && values.regular_price !== "") {
      let regular_price = parseFloat(values.regular_price);
      let val = regular_price - parseFloat(values.special_price);
      let dis = (val * 100) / regular_price;
      setFieldValue("discount", Math.round(dis));
    }
  };

  setRegularPrice = (e:any, setFieldValue: any) => {
    if(e.target.value === 'Cases'){
      setFieldValue("regular_price", this.state.prices.case_price);
    }
    if(e.target.value === 'Bottle'){
      setFieldValue("regular_price", this.state.prices.bottle_price);
    }
  }
  resetBrandImages = (index: number) => {
    let arr = this.state.brand_images_array.filter(
      (item: string, i: number) => i != index
    );
    this.setState({
      brand_images_array: arr,
    });
  };
  render() {
    const FILE_SIZE = 160 * 1024;
    const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png"];
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);
    const validate = Yup.object({
      category: Yup.string().required("Category is required."),
      brand: Yup.string().required("Brand is required."),
      description: Yup.string().required("Description is required."),
      name: Yup.string().required("Special name is required."),
      country: Yup.string().required("country is required."),
      state: Yup.array().min(1, "Pick at least 1 state."),
      start_date: Yup.date()
        .min(
          yesterday,
          "Start date cannot be in the past."
        )
        .required("Start date is required."),
      end_date: Yup.date()
        .min(Yup.ref("start_date"), "End date cannot be in the past.")
        .required("End date is required."),
      bottle: Yup.string().required("Bottle is required."),
      regular_price: Yup.string()
        .matches(/^[0-9]\d*(\.\d+)?$/, "Regular price is not valid.")
        .required("Regular price is required."),
      special_price: Yup.string()
        .matches(/^[0-9]\d*(\.\d+)?$/, "Special price is not valid.")
        .required("Special price is required."),
      discount: Yup.string()
        .matches(/^[0-9]\d*(\.\d+)?$/, "Discount is not valid.")
        .required("Discount is required."),
      size: Yup.string()
        .matches(/^[0-9]+$/, "Must be only digits.")
        .required("Size is required."),
      status: Yup.string().required("Status is required."),
      image_1: Yup.mixed()
        .test(
          "fileSize",
          "File too large",
          (value:any) => !value || (value && value.size <= FILE_SIZE)
        )
        .test(
          "fileFormat",
          "Unsupported Format",
          (value:any)  => !value || (value && SUPPORTED_FORMATS.includes(value.type))
        ),
    });
    const initialValues: productForm = {
      category: "",
      name: "",
      brand: "",
      description: "",
      country: "US",
      state: [],
      start_date: "",
      end_date: "",
      bottle: "",
      regular_price: "",
      special_price: "",
      image_1: "",
      discount: "",
      status: "",
      size: "",
    };
    return (
      <div className="buuz_right_outer">
        <div className="buuz_inner_heading">
          <h1>Add Specials & New Release</h1>
        </div>
        <div className="buuz_connectus_wrapper">
          {this.state.showAlert && (
            <Alert variant={this.state.variant}>{this.state.message}</Alert>
          )}
          <Formik
            initialValues={initialValues}
            validationSchema={validate}
            onSubmit={(values, actions) => {
              if(this.state.brand_images_array.length === 0 && values.image_1 === ''){
                actions.setFieldTouched('image_1',true);
                actions.setFieldError('image_1',"image is required.");
                actions.setSubmitting(false);
                return;
              }
              this.addProduct(values);
              actions.resetForm();
            }}
          >
            {({
              setFieldValue,
              values,
              handleChange
              /* and other goodies */
            }) => (
              <Form>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <Field
                        as="select"
                        className="form-control"
                        name="category"
                      >
                        <option>Special Category</option>
                        <option value="wine">Wine</option>
                        <option value="liquor">Liquor</option>
                        <option value="beer">Beer</option>
                        {/* <option value="Other Special">Other Special</option> */}
                      </Field>
                      <ErrorMessage
                        name="category"
                        component="span"
                        className="text-danger"
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        name="brand"
                        onChange={($e) =>
                          this.getBrand(values.category, setFieldValue, $e)
                        }
                        placeholder="Enter Brand"
                        value={this.state.brand}
                      />
                      <ul className="list-group">
                        {this.state.brand_name_array.length > 0 &&
                          this.state.brand_name_array.map(
                            (data: product, index: number) => (
                              <li
                                className="list-group-item"
                                key={index}
                                onClick={() =>
                                  this.handleClick(data, values.category)
                                }
                              >
                                {data.name}
                              </li>
                            )
                          )}
                      </ul>
                      <ErrorMessage
                        name="brand"
                        component="span"
                        className="text-danger"
                      />
                    </div>
                    <div className="form-group">
                      <Field
                        as="textarea"
                        className="form-control"
                        name="description"
                        rows={4}
                        placeholder="Special Description"
                      ></Field>
                      <ErrorMessage
                        name="description"
                        component="span"
                        className="text-danger"
                      />
                    </div>
                    <div className="form-group">
                      <Select
                        isMulti
                        name="state"
                        options={this.state.states}
                        className="basic-multi-select form-control"
                        classNamePrefix="select state"
                        onChange={(data: any) => {
                          setFieldValue("state", data);
                        }}
                      />
                      <ErrorMessage
                        name="state"
                        component="span"
                        className="text-danger"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <Field
                        type="text"
                        name="name"
                        className="form-control"
                        placeholder="Special Name"
                      />
                      <ErrorMessage
                        name="name"
                        component="span"
                        className="text-danger"
                      />
                    </div>
                    <div className="uploaded_img">
                      {this.state.brand_images_array.length > 0 &&
                        this.state.brand_images_array.map(
                          (data: any, index: any) => (
                            <div key={index}>
                              <i
                                className="fa fa-times"
                                aria-hidden="true"
                                onClick={() => this.resetBrandImages(index)}
                              ></i>
                              {values.category === "wine" && (
                                <img
                                  src={
                                    data
                                      ? `https://buuzapp.com/upload/wine_orig/${data}`
                                      : "/images/logo.png"
                                  }
                                  alt=""
                                />
                              )}
                              {values.category === "liquor" && (
                                <img
                                  src={
                                    data
                                      ? `https://buuzapp.com/upload/liquor_orig/${data}`
                                      : "/images/logo.png"
                                  }
                                  alt=""
                                />
                              )}
                              {values.category === "beer" && (
                                <img
                                  src={
                                    data
                                      ? `https://buuzapp.com/upload/beer_orig/${data}`
                                      : "/images/logo.png"
                                  }
                                  alt=""
                                />
                              )}
                              {values.category === "Other Special" && (
                                <img
                                  src={data ? data : "/images/logo.png"}
                                  alt=""
                                />
                              )}
                            </div>
                          )
                        )}
                    </div>
                    <div className="form-group">
                      <input
                        type="file"
                        name="image_1"
                        className="form-control"
                        placeholder="Images1"
                        onChange={($e: any) =>
                          setFieldValue("image_1", $e.target.files[0])
                        }
                      />
                      <ErrorMessage
                        name="image_1"
                        component="span"
                        className="text-danger"
                      />
                    </div>
                    <div className="form-group">
                      <Field
                        type="text"
                        name="country"
                        className="form-control"
                        placeholder="United States"
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="form-group col-md-6">
                    <Field
                      type="date"
                      name="start_date"
                      className="form-control"
                      placeholder="Start Date"
                    />
                    <ErrorMessage
                      name="start_date"
                      component="span"
                      className="text-danger"
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <Field
                      type="date"
                      name="end_date"
                      className="form-control"
                      placeholder="End Date"
                    />
                    <ErrorMessage
                      name="end_date"
                      component="span"
                      className="text-danger"
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <Field
                      type="text"
                      name="size"
                      className="form-control"
                      placeholder="Minimum Required Quantity"
                    />
                    <ErrorMessage
                      name="size"
                      component="span"
                      className="text-danger"
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <select
                      className="form-control"
                      name="bottle"
                      placeholder="Select Sizes"
                      onChange={(e: any) => {
                          handleChange(e);
                          this.setRegularPrice(e, setFieldValue);
                      }}
                    >
                      <option>Special Sizes</option>
                      <option value="Cases">Cases</option>
                      <option value="Bottle">Bottle</option>
                    </select>
                    <ErrorMessage
                      name="bottle"
                      component="span"
                      className="text-danger"
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <Field
                      type="text"
                      name="regular_price"
                      className="form-control"
                      placeholder="$ Regular Price"
                      readOnly
                    />
                    <ErrorMessage
                      name="regular_price"
                      component="span"
                      className="text-danger"
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <Field
                      type="text"
                      name="special_price"
                      className="form-control"
                      placeholder="$ Special Price"
                      onKeyUp={(e: any) => {
                        handleChange(e);
                        this.calculateDiscount(values, setFieldValue);
                      }}
                    />
                    <ErrorMessage
                      name="special_price"
                      component="span"
                      className="text-danger"
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <Field
                      type="text"
                      name="discount"
                      className="form-control"
                      placeholder="Discount"
                      disabled
                    />
                    <ErrorMessage
                      name="discount"
                      component="span"
                      className="text-danger"
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <Field
                      as="select"
                      className="form-control"
                      name="status"
                      placeholder="Status"
                    >
                      <option>Status</option>
                      <option value="active">Active</option>
                      <option value="inactive">Inactive</option>
                    </Field>
                    <ErrorMessage
                      name="status"
                      component="span"
                      className="text-danger"
                    />
                  </div>
                  <div className="form-group col-md-12 mt-4">
                    <button
                      type="submit"
                      className="btn btn-primary text-uppercase"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return { state };
};

const mapDispatchToProps = (dispatch: any) => ({
  setApiLoader: () => dispatch(setApiLoader({})),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddSpecial);

interface IProps {
  history: History;
  setApiLoader(): void;
}

interface IState {
  showAlert: boolean;
  variant: string;
  message: string;
  brand_name_array: Array<product>;
  brand_images_array: any;
  brand: string;
  states:Array<state_details>;
  prices : {
    case_price: string;
    bottle_price: string;
    id:string;
  }
}

interface state_details{
  label: string;
  value: number;
}

interface productForm {
  category: string;
  name: string;
  brand: string;
  description: string;
  country: string;
  state: [];
  start_date: string;
  end_date: string;
  bottle: string;
  regular_price: string;
  special_price: string;
  image_1: any;
  size: string;
  discount: string;
  status: string;
}

interface product {
  id:string;
  name: string;
  image_1: string;
  image_2:string;
  quantity: number;
  bottle_price: string;
  case_price: string;
  brand: string;
}