import React from "react";
import { History } from 'history';
import { connect } from 'react-redux';
import { Formik, Form, Field, ErrorMessage } from "formik";
//import { Alert } from 'react-bootstrap';
import axios from "axios";
import constants from "../../constants";
import { setApiLoader } from  '../../redux/actions/distributor';
import ReactPaginate from 'react-paginate';
import { Modal,Button } from 'react-bootstrap';
import * as Yup from "yup";
import { history } from "../../routers/AppRoutes";

class Portfolio extends React.Component<IProps,IState>{
    constructor(props:IProps){
        super(props);
        this.state = {
            showAlert : false,
            variant : '',
            message : '',
            products : [],
            activeTab : '',
            totalPages : 1,
            searchText : '',
            brands_array : [],
            page_number : 1,
            alert_popup : {
                show : false,
                header : '',
                body : '',
                product_deleted : '',
                product_id : '',
                product_status : '',
                type : ''
            },
            edit_popup : {
                show : false,
                bottle_price : '',
                case_price : '',
                product_id : ''
            }
        }
    }
    addPortfolio = () =>{
        this.props.history.push('/add_portfolio');
    }
    hideAlert = () =>{
        setTimeout(()=>{
            this.setState(prevState =>({
              showAlert : !prevState.showAlert
            }))
          },4000);
    }
    componentDidMount = () =>{
        let data = {
            page : '1',
            limit : '10',
            brand : '',
            search : ''
        }
        this.getProducts(data);
    }
    getProducts = async(data:any) =>{
        try {
            this.props.setApiLoader(); //set Api loader
            let url:string = `${constants.BASE_API_URL}protfolio/get_portfolio_products?page=${data.page}&limit=${data.limit}&brand=${data.brand}&search=${data.search}`;
            const response = await axios.get(url,{headers:{ 'Authorization' : `Bearer ${localStorage.getItem('token')}` }});
            this.props.setApiLoader(); //set Api loader
            this.setState(prevState =>({
                showAlert: !prevState.showAlert,
                variant : 'success',
                message : response.data.message,
                products : [...response.data.data.products],
                totalPages : response.data.data.totalPages
            })); 
            
            //if brand set to all then only update brand array in state
            if(data.brand === ''){
                //set the brands into the state to use in filter
                let brands:any = [];
                this.state.products.forEach((data:any)=>{
                    brands.push(data.brand);
                });
                brands = brands.filter(function( item:any, index:number, inputArray:any ) {
                    return inputArray.indexOf(item) == index;
                });
                this.setState({brands_array:brands});
            }

            this.hideAlert();
            
        } catch (error) {
            this.props.setApiLoader(); //set Api loader
            this.setState(prevState => ({
                showAlert: !prevState.showAlert,
                variant : 'danger',
                message : error.response.data.message
              }));
      
              this.hideAlert();
        }
    }
    handleCLick = (val:string) =>{
        this.setState((prevState)=>({
            activeTab : val,
            searchText : ''
        }));
        let data = {
            page : '1',
            limit : '10',
            brand : val,
            search : this.state.searchText
        }
        this.getProducts(data);
    }
    handlePageClick = (data:any) =>{
      let filter = {
        page : parseInt(data.selected)+1,
        limit : '10',
        brand : this.state.activeTab,
        search : this.state.searchText
      }
      //set page_number into state
      this.setState({page_number : parseInt(data.selected) + 1})
      this.getProducts(filter);
    }
    searchProducts = ($event:any) =>{
       this.setState({searchText:$event.target.value,activeTab:''},()=>{
        let filter = {
            page : '1',
            limit : '10',
            brand : this.state.activeTab,
            search : this.state.searchText
          }
          this.getProducts(filter);
       });
    }

    updateProduct=async(id:string,update_object:any,type:string) =>{
        try {
          this.props.setApiLoader(); //set Api loader
          let url: string = `${constants.BASE_API_URL}protfolio/update_portfolio_product`;
          //alert(status);
          const response = await axios.put(url,{id,update_object,type}, {
            headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
          });
          this.props.setApiLoader(); //set Api loader
          this.setState((prevState) => ({
            showAlert: !prevState.showAlert,
            variant: "success",
            message: response.data.message,
          }));
          //set filter to update the products
          let filter = {
            page: this.state.page_number,
            limit : '10',
            brand : this.state.activeTab,
            search : this.state.searchText
          };
          //update products
          this.getProducts(filter);
          console.log(response.data.data)
    
          this.hideAlert();
        } catch (error) {
          this.props.setApiLoader(); //set Api loader
          this.setState((prevState) => ({
            showAlert: !prevState.showAlert,
            variant: "danger",
            message: error.response.data.message,
          }));
    
          this.hideAlert();
        }
      }

    handleOpen = (id:string,status:string,is_deleted:string,type:string) =>{
        this.setState((prevState)=>({
          alert_popup:{
            ...prevState.alert_popup,
            show : !prevState.alert_popup.show,
            product_id : id,
            product_status : status,
            product_deleted : is_deleted,
            header : 'Confirmation Popup!',
            body : (type=="edit") ? 'Do you want to change the status of this product?' : 'Do you want to delete this product?',
            type
          }
        }));
    }
    
    handleClose = () =>{
        this.setState((prevState)=>({
          alert_popup:{
            ...prevState.alert_popup,
            show : !prevState.alert_popup.show,
          }
        })); 
    }

    updateStatusOfProduct = () =>{
        const id = this.state.alert_popup.product_id;
        let status = this.state.alert_popup.product_status;
        const is_deleted = this.state.alert_popup.product_deleted;
        this.handleClose();
        //if type is edit then only change status not at time of delete
        if(this.state.alert_popup.type === 'edit'){
            status = (status==='active') ? 'inactive' : 'active';          
        }
        //object to be updated
        let update_object:any={
            is_deleted,status
        }
        //if funtion is called for api delete then pass delete as type parameter
        //because it will also delete the product from special release
        if(is_deleted === 'yes'){
           this.updateProduct(id,update_object,'delete');            
        }else{
           this.updateProduct(id,update_object,'update');
        }
    }

    handleEditClose = () =>{
        this.setState((prevState)=>({
            edit_popup : {
                ...prevState.edit_popup,
                show : !prevState.edit_popup.show
            }
        }));
    }

    handleEditOpen = (data:any) =>{
        this.setState((prevState)=>({
            edit_popup : {
                ...prevState.edit_popup,
                show : !prevState.edit_popup.show,
                bottle_price : data.bottle_price,
                case_price : data.case_price,
                product_id : data.id
            }
        }));
    }

    updatePortfolioProduct = (values:productForm) =>{
        let update_object = {
            bottle_price : values.bottle_price,
            case_price : values.case_price
        }
        const id = this.state.edit_popup.product_id;
        this.updateProduct(id,update_object,'update');
        this.handleEditClose()
    }

    render(){
        const validate = Yup.object({
            bottle_price : Yup.string().matches(/^[0-9]\d*(\.\d+)?$/, "Bottle price is not valid.").required("Bottle price is required."),
            case_price : Yup.string().matches(/^[0-9]\d*(\.\d+)?$/, "Case price is not valid.").required("Case price is required.")
        });
        const initialValues : productForm = {
            bottle_price : this.state.edit_popup.bottle_price,
            case_price : this.state.edit_popup.case_price
        }
        return(
            <div className="buuz_right_outer">
                {/* <div className="buuz_inner_heading">
                    <h1>Portfolio</h1>
                </div> */}
                <div className="portfolio_wrapper">
                   {/* {this.state.showAlert && <Alert variant={this.state.variant}>{this.state.message}</Alert> } */}
                    <ul className="nav nav-tabs align-items-center">
                        <form>
                            <div className="form-group mr-4 mb-0">
                                <input type="text" className="form-control" name="search" placeholder="Search Products" value={this.state.searchText} onChange={this.searchProducts} />
                            </div>
                        </form>
                        {this.state.brands_array.length > 1 &&
                          <li>
                            <a onClick={()=>this.handleCLick('')} className={this.state.activeTab === "" ?  "active" : ""} data-toggle="tab" href="#all">All</a>
                          </li>
                         }
                        {this.state.brands_array.length > 0 && this.state.brands_array.map((data:any,index:number)=>(
                          <li key={index}>
                              <a onClick={()=>this.handleCLick(data)} data-toggle="tab" className={this.state.activeTab === data ?  "active" : ""}>{data}</a>
                          </li>                            
                        ))}                        
                    </ul>

                    <div className="tab-content">
                    <div id="all" className="tab-pane active">
                        <div className="row mb-4 portfolio_table_heading align-items-center">
                            <div className="col-md-8">
                                <h4>Portfolio Products</h4>
                                <p>Distinctively exploit optimal alignments for intuitive business applications through revolutionary catalys</p>
                            </div>
                            <div className="col-md-4 text-right">
                                <button type="button" onClick={this.addPortfolio} className="btn btn-primary"><i className="fa fa-plus mr-2"></i> Add product to portfolio</button>
                            </div>
                        </div>
                        <div className="table-responsive portfolio_table">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th style={{width:70}}>Image</th>
                                        <th style={{width:100}}>Name</th>
                                        <th>Brand</th>
                                        <th>Type</th>
                                        <th>Unit Type</th>
                                        <th>Unit Size</th>
                                        <th>Status</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.products.length > 0 && this.state.products.map((data:any)=>(
                                        <tr key={data.id}>
                                            <td>
                                               {data.brand === 'liquor' && <img src={(data.image_1) ? `https://buuzapp.com/upload/liquor_orig/${data.image_1}` : '/images/logo.png'} alt="brand img" width="70" />}
                                               {data.brand === 'beer' && <img src={(data.image_1) ? `https://buuzapp.com/upload/beer_orig/${data.image_1}` : '/images/logo.png'} alt="brand img" width="70" />}
                                               {data.brand === 'wine' && <img src={(data.image_1) ? `https://buuzapp.com/upload/wine_orig/${data.image_1}` : '/images/logo.png'} alt="brand img" width="70" />}
                                                
                                            </td>
                                            <td className="name_td"><p>{data.name}</p></td>
                                            <td>{data.brand}</td>
                                            <td>{(data.category) ? data.category : 'N/A'}</td>
                                            <td>{(data.unit_size) ? data.unit_size : 'N/A'}</td>
                                            <td>{(data.quantity) ? data.quantity : 'N/A'}</td>
                                            <td className="status_btn">
                                                <label className="switch">
                                                    <input type="checkbox" checked={(data.status === 'active') ? true : false} onClick={()=>this.handleOpen(data.id,data.status,data.is_deleted,'edit')} readOnly/>
                                                    <span className="slider round"></span>
                                                </label>
                                            </td>
                                            <td className="action_btn">
                                                <div className="d-flex">
                                                    <span className="portfolio_view" onClick={()=>history.push('/view_portfolio/'+data.id)}><i className="fa fa-eye" aria-hidden="true"></i></span>
                                                    <span className="portfolio_edit" onClick={()=>this.handleEditOpen(data)}><i className="fa fa-pencil" aria-hidden="true"></i></span>
                                                    <span className="portfolio_delete" onClick={()=>this.handleOpen(data.id,data.status,"yes",'delete')}><i className="fa fa-trash-o" aria-hidden="true"></i></span>
                                                </div>
								            </td>
                                        </tr>
                                    ))}                                     
                                </tbody>
                            </table>
                         </div>
                        <div className="mt-4 text-right d-flex" style={{justifyContent:'flex-end'}}>
                            <ReactPaginate
                                previousLabel={'previous'}
                                nextLabel={'next'}
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={this.state.totalPages}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={5}
                                onPageChange={this.handlePageClick}
                                containerClassName={'pagination'}
                                activeClassName={'active'}
                                pageClassName={'page-item'}
                                pageLinkClassName={'page-link'}
                                previousClassName={'page-item'}
                                previousLinkClassName={'page-link'}
                                nextClassName={'page-item'}
                                nextLinkClassName={'page-link'}
                             />     
                        </div>
                       </div>
                    </div>
                </div>
                 {/* Popup to confirm user to delete or change status of product */}
                <Modal show={this.state.alert_popup.show} onHide={this.handleClose} backdrop="static" keyboard={false} animation={false}>
                    <Modal.Header closeButton>
                        <Modal.Title>{this.state.alert_popup.header}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.state.alert_popup.body}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleClose}>
                        Close
                        </Button>
                        <Button variant="primary" onClick={this.updateStatusOfProduct}>Submit</Button>
                    </Modal.Footer>
                </Modal>
                 {/* Popup to edit portfolio product */}
                <Modal
                    show={this.state.edit_popup.show}
                    onHide={this.handleEditClose}
                    backdrop="static"
                    keyboard={false}
                    animation={false}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Edit Product Prices</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Formik initialValues={initialValues} validationSchema={validate} enableReinitialize
                            onSubmit={(values, actions) => {
                            this.updatePortfolioProduct(values);
                            actions.resetForm();
                        }} >
                            <Form>
                            <div className="form-group with_icon_field">
                                <Field type="text" className="form-control" name="bottle_price" placeholder="Bottle Price"/>
                                <div className="form_control_icon">
                                    <img src="/images/dollar.svg" alt="" />
                                </div>
                                <ErrorMessage name="bottle_price" component="span" className="text-danger" />
                            </div>
                            <div className="form-group with_icon_field">
                                <Field type="text" className="form-control" name="case_price" placeholder="Case Price"/>
                                <div className="form_control_icon">
                                    <img src="/images/dollar.svg" alt="" />
                                </div>
                                <ErrorMessage name="case_price" component="span" className="text-danger" />
                            </div>
                            <div className="custom-control custom-checkbox my-4">
                                <Field type="checkbox" className="custom-control-input" id="customControlValidation1" />
                                <label className="custom-control-label" htmlFor="customControlValidation1">Rainbow/Mix Case Available</label>
                            </div>
                            <div className="text-center">
                                <button type="submit" className="btn btn-primary text-uppercase">Update</button>
                            </div>
                            </Form>
                            </Formik>
                    </Modal.Body>
                </Modal>
            </div>
        )
    }
}

const mapStateToProps = (state:any) => {
    return { state };
};

const mapDispatchToProps = (dispatch:any) => ({
    setApiLoader: () => dispatch(setApiLoader({}))
});

export default connect(mapStateToProps, mapDispatchToProps)(Portfolio);


interface IProps {
    history:History;
    setApiLoader():void;
}
  
interface IState {
    showAlert : boolean;
    variant : string;
    message : string;
    products : any;
    activeTab : string;
    totalPages : number;
    searchText : string;
    brands_array : [];
    page_number : number;
    alert_popup :{
        show : boolean,
        header : string,
        body : string,
        product_id:string,
        product_status:string,
        product_deleted:string,
        type : string
    }
    edit_popup : {
        show : boolean,
        bottle_price : string,
        case_price : string,
        product_id : string
    }
}

interface productForm{
    bottle_price : string;
    case_price : string;
}