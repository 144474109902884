import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import distributorReducer from '../reducers/distributor';

//const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default () => {
  const store = createStore(distributorReducer,applyMiddleware(thunk)
    //composeEnhancers(applyMiddleware(thunk))
  );
  store.subscribe(()=>{
    console.log(store.getState())
  })

  return store;
};
