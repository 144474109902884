import React from "react";
import { History } from 'history';
import { connect } from 'react-redux';
import { Formik, Form, Field, ErrorMessage } from "formik";
//import { Alert } from 'react-bootstrap';
import axios from "axios";
import constants from "../../constants";
import { setApiLoader } from  '../../redux/actions/distributor';
import { Modal } from 'react-bootstrap';
import * as Yup from "yup";
import parse from 'html-react-parser';

class ViewPortfolio extends React.Component<IProps,IState>{
    constructor(props:IProps){
        super(props);
        this.state = {
            showAlert : false,
            variant : '',
            message : '',
            products : {},
            edit_popup : {
                show : false,
                bottle_price : '',
                case_price : '',
                product_id : ''
            }
        }
    }
    hideAlert = () =>{
        setTimeout(()=>{
            this.setState(prevState =>({
              showAlert : !prevState.showAlert
            }))
          },4000);
    }
    componentDidMount = () =>{
        this.getProducts();
    }
    getProducts = async() =>{
        try {
            let _url:any = window.location.href;
            let paramString = _url.split('/');
            this.props.setApiLoader(); //set Api loader
            let url:string = `${constants.BASE_API_URL}protfolio/get_portfolio_product_id/${paramString[4]}`;
            const response = await axios.get(url,{headers:{ 'Authorization' : `Bearer ${localStorage.getItem('token')}` }});
            this.props.setApiLoader(); //set Api loader
            this.setState(prevState =>({
                showAlert: !prevState.showAlert,
                variant : 'success',
                message : response.data.message,
                products : response.data.data.product[0],
            })); 
            console.log(response.data);
            this.hideAlert();
            
        } catch (error) {
            this.props.setApiLoader(); //set Api loader
            this.setState(prevState => ({
                showAlert: !prevState.showAlert,
                variant : 'danger',
                message : error.response.data.message
              }));
      
              this.hideAlert();
        }
    }

    updateProduct=async(id:string,update_object:any,type:string) =>{
        try {
          this.props.setApiLoader(); //set Api loader
          let url: string = `${constants.BASE_API_URL}protfolio/update_portfolio_product`;
          //alert(status);
          const response = await axios.put(url,{id,update_object,type}, {
            headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
          });
          this.props.setApiLoader(); //set Api loader
          this.setState((prevState) => ({
            showAlert: !prevState.showAlert,
            variant: "success",
            message: response.data.message,
          }));
    
          this.hideAlert();
        } catch (error) {
          this.props.setApiLoader(); //set Api loader
          this.setState((prevState) => ({
            showAlert: !prevState.showAlert,
            variant: "danger",
            message: error.response.data.message,
          }));
    
          this.hideAlert();
        }
      }
    handleEditClose = () =>{
        this.setState((prevState)=>({
            edit_popup : {
                ...prevState.edit_popup,
                show : !prevState.edit_popup.show
            }
        }));
    }

    handleEditOpen = (data:any) =>{
        this.setState((prevState)=>({
            edit_popup : {
                ...prevState.edit_popup,
                show : !prevState.edit_popup.show,
                bottle_price : data.bottle_price,
                case_price : data.case_price,
                product_id : data.id
            }
        }));
    }

    updatePortfolioProduct = (values:productForm) =>{
        let update_object = {
            bottle_price : values.bottle_price,
            case_price : values.case_price
        }
        const id = this.state.edit_popup.product_id;
        this.updateProduct(id,update_object,'update');
        this.handleEditClose()
    }

    render(){
        const validate = Yup.object({
            bottle_price : Yup.string().matches(/^[0-9]\d*(\.\d+)?$/, "Bottle price is not valid.").required("Bottle price is required."),
            case_price : Yup.string().matches(/^[0-9]\d*(\.\d+)?$/, "Case price is not valid.").required("Case price is required.")
        });
        const initialValues : productForm = {
            bottle_price : this.state.edit_popup.bottle_price,
            case_price : this.state.edit_popup.case_price
        }
        return(
            <div className="buuz_right_outer">
                <div className="buuz_inner_heading">
                    <h1>Porfolio Detail</h1>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="buuz_connect_status_block">
                            <ul className="portfolio_detail">
                                <li>
                                    <h5>Name :</h5>
                                    <p>{this.state.products.name}</p>
                                </li>
                                <li>
                                    <h5>Type :</h5>
                                    <p>{(this.state.products.category) ? this.state.products.category : 'N/A'}</p>
                                </li>
                                <li>
                                    <h5>Brand :</h5>
                                    <p>{this.state.products.brand}</p>
                                </li>
                                <li>
                                    <h5>Image :</h5>
                                    <p>
                                      {this.state.products.brand === 'liquor' && <img src={(this.state.products.image_1) ? `https://buuzapp.com/upload/liquor_orig/${this.state.products.image_1}` : '/images/logo.png'} alt="brand img" width="40" />}
                                      {this.state.products.brand === 'beer' && <img src={(this.state.products.image_1) ? `https://buuzapp.com/upload/beer_orig/${this.state.products.image_1}` : '/images/logo.png'} alt="brand img" width="40" />}
                                      {this.state.products.brand === 'wine' && <img src={(this.state.products.image_1) ? `https://buuzapp.com/upload/wine_orig/${this.state.products.image_1}` : '/images/logo.png'} alt="brand img" width="40" />}
                                    </p>
                                </li>
                                <li>
                                    <h5>Product Description :</h5>
                                    <p>{(this.state.products.description) ? parse(this.state.products.description) : 'N/A'}</p>
                                </li>
                                <li>
                                    <h5>Container :</h5>
                                    <p>N/A</p>
                                </li>
                                <li>
                                    <h5>Unit Size :</h5>
                                    <p>{(this.state.products.unit_size) ? this.state.products.unit_size : 'N/A'}</p>
                                </li>
                                <li>
                                    <h5>ABV :</h5>
                                    <p>{(this.state.products.abv) ? this.state.products.abv : 'N/A'}</p>
                                </li>
                                <li>
                                    <h5>Case Price : <i className="fa fa-pencil" aria-hidden="true" onClick={()=>this.handleEditOpen(this.state.products)}></i></h5>
                                    <p>${this.state.products.case_price}</p>
                                </li>
                                <li>
                                    <h5>Bottle Price : <i className="fa fa-pencil" aria-hidden="true" onClick={()=>this.handleEditOpen(this.state.products)}></i></h5>
                                    <p>${this.state.products.bottle_price}</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                 {/* Popup to edit portfolio product */}
                 <Modal
                    show={this.state.edit_popup.show}
                    onHide={this.handleEditClose}
                    backdrop="static"
                    keyboard={false}
                    animation={false}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Edit Product Prices</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Formik initialValues={initialValues} validationSchema={validate} enableReinitialize
                            onSubmit={(values, actions) => {
                            this.updatePortfolioProduct(values);
                            actions.resetForm();
                        }} >
                            <Form>
                            <div className="form-group with_icon_field">
                                <Field type="text" className="form-control" name="bottle_price" placeholder="Bottle Price"/>
                                <div className="form_control_icon">
                                    <img src="/images/dollar.svg" alt="" />
                                </div>
                                <ErrorMessage name="bottle_price" component="span" className="text-danger" />
                            </div>
                            <div className="form-group with_icon_field">
                                <Field type="text" className="form-control" name="case_price" placeholder="Case Price"/>
                                <div className="form_control_icon">
                                    <img src="/images/dollar.svg" alt="" />
                                </div>
                                <ErrorMessage name="case_price" component="span" className="text-danger" />
                            </div>
                            <div className="custom-control custom-checkbox my-4">
                                <Field type="checkbox" className="custom-control-input" id="customControlValidation1" />
                                <label className="custom-control-label" htmlFor="customControlValidation1">Rainbow/Mix Case Available</label>
                            </div>
                            <div className="text-center">
                                <button type="submit" className="btn btn-primary text-uppercase">Update</button>
                            </div>
                            </Form>
                            </Formik>
                    </Modal.Body>
                </Modal>
	        </div>
        )
    }
}

const mapStateToProps = (state:any) => {
    return { state };
};

const mapDispatchToProps = (dispatch:any) => ({
    setApiLoader: () => dispatch(setApiLoader({}))
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewPortfolio);


interface IProps {
    history:History;
    setApiLoader():void;
}
  
interface IState {
    showAlert : boolean;
    variant : string;
    message : string;
    products : any;
    edit_popup : {
        show : boolean,
        bottle_price : string,
        case_price : string,
        product_id : string
    }
}

interface productForm{
    bottle_price : string;
    case_price : string;
}