import React from "react";
import { History } from "history";
import { connect } from "react-redux";
import { Alert } from "react-bootstrap";
import axios from "axios";
import constants from "../../constants";
import { setApiLoader } from "../../redux/actions/distributor";
import ReactPaginate from "react-paginate";
import { Modal,Button } from 'react-bootstrap';

class SpecialsList extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      showAlert: false,
      variant: "",
      message: "",
      products: [],
      totalPages: 1,
      searchText: "",
      setDisabled: false,
      page_number : 1,
      alert_popup : {
        show : false,
        header : '',
        body : '',
        product_deleted : '',
        product_id : '',
        product_status : '',
        type : ''
      }
    };
  }
  addSpecial = () => {
    this.props.history.push("/add_special");
  };
  hideAlert = () => {
    setTimeout(() => {
      this.setState((prevState) => ({
        showAlert: !prevState.showAlert,
      }));
    }, 4000);
  };
  componentDidMount = () => {
    let data = {
      page: "1",
      limit: "10",
      search: "",
    };
    this.getProducts(data);
  };
  getProducts = async (data: any) => {
    try {
      this.props.setApiLoader(); //set Api loader
      let url: string = `${constants.BASE_API_URL}protfolio/get_special_release?page=${data.page}&limit=${data.limit}&search=${data.search}`;
      const response = await axios.get(url, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      });
      this.props.setApiLoader(); //set Api loader
      this.setState((prevState) => ({
        showAlert: !prevState.showAlert,
        variant: "success",
        message: response.data.message,
        products: [...response.data.data.products],
        totalPages: response.data.data.totalPages,
        setDisabled: false,
      }));

      //console.log(response.data.data)

      this.hideAlert();
    } catch (error) {
      this.props.setApiLoader(); //set Api loader
      this.setState((prevState) => ({
        showAlert: !prevState.showAlert,
        variant: "danger",
        message: error.response.data.message,
      }));

      this.hideAlert();
    }
  };
  searchProducts = ($event: any) => {
    this.setState(
      { searchText: $event.target.value, setDisabled: true },
      () => {
        let filter = {
          page: "1",
          limit: "10",
          search: this.state.searchText,
        };
        this.getProducts(filter);
      }
    );
  };
  handlePageClick = (data: any) => {
    let filter = {
      page: parseInt(data.selected) + 1,
      limit: "10",
      search: this.state.searchText,
    };
    //set page_number into state
    this.setState({page_number : parseInt(data.selected) + 1})
    this.getProducts(filter);
  };
  editProduct = (id: string) => {
    this.props.history.push(`/edit_special_release/${id}`);
  };

  changeStatus=async(id:string,status:string,is_deleted:string) =>{
    try {
      this.props.setApiLoader(); //set Api loader
      let url: string = `${constants.BASE_API_URL}protfolio/update_special_release_product`;
      if(this.state.alert_popup.type === 'edit'){
        status = (status==='active') ? 'inactive' : 'active';          
      }
      //alert(status);
      const response = await axios.put(url,{id,status,is_deleted}, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      });
      this.props.setApiLoader(); //set Api loader
      this.setState((prevState) => ({
        showAlert: !prevState.showAlert,
        variant: "success",
        message: response.data.message,
      }));
      //set filter to update the products
      let filter = {
        page: this.state.page_number,
        limit: "10",
        search: this.state.searchText,
      };
      //update products
      this.getProducts(filter);
      console.log(response.data.data)

      this.hideAlert();
    } catch (error) {
      this.props.setApiLoader(); //set Api loader
      this.setState((prevState) => ({
        showAlert: !prevState.showAlert,
        variant: "danger",
        message: error.response.data.message,
      }));

      this.hideAlert();
    }
  }

  handleOpen = (id:string,status:string,is_deleted:string,type:string) =>{
    this.setState((prevState)=>({
      alert_popup:{
        ...prevState.alert_popup,
        show : !prevState.alert_popup.show,
        product_id : id,
        product_status : status,
        product_deleted : is_deleted,
        header : 'Confirmation Popup!',
        body : (type=="edit") ? 'Do you want to change the status of this product?' : 'Do you want to delete this product?',
        type
      }
    }));
  }

  handleClose = () =>{
    this.setState((prevState)=>({
      alert_popup:{
        ...prevState.alert_popup,
        show : !prevState.alert_popup.show,
      }
    })); 
  }

  updateStatusOfProduct = () =>{
      const id = this.state.alert_popup.product_id;
      const status = this.state.alert_popup.product_status;
      const is_deleted = this.state.alert_popup.product_deleted;
      this.handleClose();
      this.changeStatus(id,status,is_deleted);
  }
  
  render() {
    return (
      <div className="buuz_right_outer">
        {/* <div className="buuz_inner_heading">
                    <h1>MARA Imports</h1>
                </div> */}
        <div className="portfolio_wrapper">
          {/* {this.state.showAlert && <Alert variant={this.state.variant}>{this.state.message}</Alert> } */}
          <div className="row mb-4">
            <div className="col-md-6">
              <form>
                <div className="form-group mr-4 mb-0">
                  <input
                    type="text"
                    className="form-control"
                    name="search"
                    placeholder="Search Products"
                    value={this.state.searchText}
                    onChange={this.searchProducts}
                    disabled={this.state.setDisabled}
                  />
                </div>
              </form>
            </div>
            <div className="col-md-6 text-right">
              <button
                type="button"
                onClick={this.addSpecial}
                className="btn btn-primary"
              >
                <i className="fa fa-plus mr-2"></i> Add special & new release
              </button>
            </div>
          </div>

          <div className="tab-content">
            <div id="all" className="tab-pane active">
              <div className="table-responsive portfolio_table">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Image</th>
                      <th>Special Name</th>
                      <th className="profolio_dis_td" style={{ width: "300" }}>
                        Brand
                      </th>
                      <th>Unit Type</th>
                      <th>Quantity</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.products.length > 0 &&
                      this.state.products.map((data: any) => (
                        <tr key={data.id}>
                          <td>
                           {data.category === 1 &&  <img src={(data.logo != '') ?  'https://buuzapp.com/upload/wine_orig/'+data.logo : data.image} alt="brand img" width="70"/>}
                           {data.category === 2 && <img src={(data.logo != '') ? 'https://buuzapp.com/upload/liquor_orig/'+data.logo : data.image} alt="brand img" width="70"/>}
                           {data.category === 3 && <img src={(data.logo != '') ? 'https://buuzapp.com/upload/beer_orig/'+data.logo : data.image} alt="brand img" width="70"/>}
                           {data.category === 4 && <img src={(data.image) ? data.image : '/images/logo.png' } alt="brand img" width="70"/>}
                          </td>
                          <td className="name_td"><p>{data.special_name}</p></td>
                          <td className="profolio_dis_td" width={300}>
                            <p>{data.brand}</p>
                          </td>
                          <td>{data.size_type}</td>
                          <td>{data.size_qty}</td>
                          <td className="status_btn">
                            <label className="switch">
                              <input type="checkbox" checked={(data.status === 'active') ? true : false} onClick={()=>this.handleOpen(data.id,data.status,data.is_deleted,'edit')} readOnly/>
                              <span className="slider round"></span>
                            </label>
                          </td>
                          <td className="action_btn">
                            <div className="d-flex">
                              <span
                                className="portfolio_edit"
                                onClick={() => this.editProduct(data.id)}
                              >
                                <i
                                  className="fa fa-pencil"
                                  aria-hidden="true"
                                ></i>
                              </span>
                              <span className="portfolio_delete" onClick={()=>this.handleOpen(data.id,data.status,"yes",'delete')}>
                                <i
                                  className="fa fa-trash-o"
                                  aria-hidden="true"
                                ></i>
                              </span>
                            </div>
                          </td>
                        </tr>
                      ))}
                      {this.state.products.length === 0 && <tr><td align="center" colSpan={7}>No data found.</td></tr>}
                  </tbody>
                </table>
              </div>
              <div
                className="mt-4 text-right d-flex"
                style={{ justifyContent: "flex-end" }}
              >
                <ReactPaginate
                  previousLabel={"previous"}
                  nextLabel={"next"}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={this.state.totalPages}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={this.handlePageClick}
                  containerClassName={"pagination"}
                  activeClassName={"active"}
                  pageClassName={"page-item"}
                  pageLinkClassName={"page-link"}
                  previousClassName={"page-item"}
                  previousLinkClassName={"page-link"}
                  nextClassName={"page-item"}
                  nextLinkClassName={"page-link"}
                />
              </div>
            </div>
          </div>
        </div>
        {/* Popup to confirm user to delete or change status of product */}
        <Modal show={this.state.alert_popup.show} onHide={this.handleClose} backdrop="static" keyboard={false} animation={false}>
          <Modal.Header closeButton>
            <Modal.Title>{this.state.alert_popup.header}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.state.alert_popup.body}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={this.updateStatusOfProduct}>Submit</Button>
          </Modal.Footer>
      </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return { state };
};

const mapDispatchToProps = (dispatch: any) => ({
  setApiLoader: () => dispatch(setApiLoader({})),
});

export default connect(mapStateToProps, mapDispatchToProps)(SpecialsList);

interface IProps {
  history: History;
  setApiLoader(): void;
}

interface IState {
  showAlert: boolean;
  variant: string;
  message: string;
  products: any;
  totalPages: number;
  searchText: string;
  setDisabled: boolean;
  page_number : number;
  alert_popup :{
    show : boolean,
    header : string,
    body : string,
    product_id:string,
    product_status:string,
    product_deleted:string,
    type : string
  }
}
