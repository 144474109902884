import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Alert } from 'react-bootstrap';
import * as Yup from "yup";
import axios from "axios";
import constants from "../constants";
import { History } from 'history';
import { Loader } from './partials/loader';
import { connect } from 'react-redux';
import { setApiLoader } from  '../redux/actions/distributor';

class Signup extends React.Component<IProps,IState>{
    constructor(props:IProps){
        super(props);
        this.state = {
            error : false,
            variant : '',
            message : ''
        }
    }
    signup = async(data:signupFormValues) =>{
        try {
            this.props.setApiLoader(); //set Api loader
            let url:string = `${constants.BASE_API_URL}user/signup`;
            data.phone_no = ('' + data.phone_no).replace(/\D/g, '');
            const response = await axios.post(url,data);
            this.props.setApiLoader(); //set Api loader
            this.setState(prevState =>({
              error: !prevState.error,
              variant : 'success',
              message : response.data.message
            }));
            setTimeout(()=>{
                this.props.history.push('/dashboard')
            },3000);
          } catch (err) {
            this.props.setApiLoader(); //set Api loader
            this.setState(prevState => ({
              error: !prevState.error,
              variant : 'danger',
              message :  err.response.data.message 
            }));
    
            setTimeout(()=>{
              this.setState(prevState =>({
                error : !prevState.error
              }))
            },4000);
          }
    }
    setformatPhoneNumber=(phoneNumber:string,setFieldValue:any,field:string) => {
        var cleaned = ('' + phoneNumber).replace(/\D/g, '');
        var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        if(match){
          setFieldValue(field, match[1] + '-' + match[2] + '-' + match[3]);
        }        
    }
  
    formatPhoneNumber=(phoneNumberString:string)=> {
        var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
        var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            return match[1] + '-' + match[2] + '-' + match[3];
        }
        return '';
    }
    render(){
        const validate = Yup.object({
            distributor_name: Yup.string().required("Distributor name is required."),
            street:Yup.string().required("Street name is required."),
            distributor_website:Yup.string().required("Website is required."),
            city:Yup.string().required("City is required."),
            state:Yup.string().required("State is required."),
            zipcode:Yup.string().matches(/^[0-9]+$/, "Must be only digits.")
                                .min(5,'Zipcode is invalid.')
                                .required("Zipcode is required."),
            app_suite:Yup.string(),
            first_name:Yup.string().required("Firstname is required."),
            last_name:Yup.string().required("Lastname is required."),
            phone_no:Yup.string().matches(/^[0-9]{3}-[0-9]{3}-[0-9]{4}$/, "Phone Number is invalid.")
                                 .min(10,'Phone Number is invalid.')
                                 .required("Phone number is required."),
            email: Yup.string().email("Email is invalid.").required("Email is required.")
        });
        const initialValues: signupFormValues = {
                    distributor_name:"",
                    street:"",
                    distributor_website:"",
                    city:"",
                    state:"",
                    zipcode:"" ,
                    app_suite:"",
                    first_name:"",
                    last_name:"",
                    phone_no:"",
                    email:"",
                };
        return(
            <div className="buuz_login_wrapper">
		        <div className="buzz_login_outer buzz_register_outer">
                    <div className="text-center">
                        {/* <img className="form_logo" src="/images/logo.png" alt="" /> */}
                    </div>
                    { this.props.requestApiLoader  && <Loader /> }
                    <div className="buuz_login_block">
                        <h1>Distributor Portal</h1>
                        {this.state.error && <Alert variant={this.state.variant}>{this.state.message}</Alert> }
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validate}
                            onSubmit={(values, actions) => {
                                this.signup(values);
                            }}
                        >
                        {({
                            setFieldValue,
                            values,
                            handleChange
                            /* and other goodies */
                        }) => (
                        <Form className="row">
                            <div className="register_inner_heading col-12">
                                <h5 className="mt-0">Distributor Details</h5>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <Field type="text" name="distributor_name" className="form-control" placeholder="Distributor Name" />
                                    <div className="form_control_icon">
                                        <img src="/images/user.png" alt="" />
                                    </div>
                                   <ErrorMessage name="distributor_name" component="span" className="text-danger"/>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <Field type="text" name="distributor_website" className="form-control" placeholder="Website" />
                                    <div className="form_control_icon">
                                        <img src="/images/website.png" alt="" />
                                    </div>
                                    <ErrorMessage name="distributor_website" component="span" className="text-danger"/>
                                </div>
                            </div>
                            <div className="col-md-9">
                                <div className="form-group">
                                    <Field type="text" name="street" className="form-control" placeholder="Street" />
                                    <div className="form_control_icon">
                                        <img src="/images/street.png" alt="" />
                                    </div>
                                    <ErrorMessage name="street" component="span" className="text-danger"/>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="form-group without_icon_field">
                                    <Field type="text" name="app_suite" className="form-control" placeholder="Apt/Suite" />
                                    <ErrorMessage name="app_suite" component="span" className="text-danger"/>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <Field type="text" name="city" className="form-control" placeholder="City" />
                                    <div className="form_control_icon">
                                        <img src="/images/city.png" alt="" />
                                    </div>
                                    <ErrorMessage name="city" component="span" className="text-danger"/>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="form-group without_icon_field">
                                    <Field type="text" name="state" className="form-control" placeholder="State" />
                                    <ErrorMessage name="state" component="span" className="text-danger"/>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="form-group without_icon_field">
                                    <Field type="text" name="zipcode" className="form-control" placeholder="Zipcode" />
                                    <ErrorMessage name="zipcode" component="span" className="text-danger"/>
                                </div>
                            </div>
                            <div className="register_inner_heading col-12">
                                <h5>Contact Details</h5>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <Field type="text" name="first_name" className="form-control" placeholder="First Name" />
                                    <div className="form_control_icon">
                                        <img src="/images/user.png" alt="" />
                                    </div>
                                    <ErrorMessage name="first_name" component="span" className="text-danger"/>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <Field type="text" name="last_name" className="form-control" placeholder="Last Name" />
                                    <div className="form_control_icon">
                                        <img src="/images/user.png" alt="" />
                                    </div>
                                    <ErrorMessage name="last_name" component="span" className="text-danger"/>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <Field type="text" name="phone_no" className="form-control" placeholder="Phone Number" onKeyDown={(e:any)=>{handleChange(e); this.setformatPhoneNumber(values.phone_no,setFieldValue,'phone_no')}} />
                                    <div className="form_control_icon">
                                        <img src="/images/phone-number.png" alt="" />
                                    </div>
                                    <ErrorMessage name="phone_no" component="span" className="text-danger"/>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <Field type="email" name="email" className="form-control" placeholder="Email" />
                                    <div className="form_control_icon">
                                        <img src="/images/email.png" alt="" />
                                    </div>
                                    <ErrorMessage name="email" component="span" className="text-danger"/>
                                </div>
                            </div>
                            <div className="col-12 text-center">
                                <div className="login_btn_wrapper">
                                    <button type="submit" className="btn btn-primary"><img src="/images/login.png" alt=""/> Send Request</button>
                                </div>
                            </div>
                        </Form>
                        )}
                        </Formik>
                    </div>
                </div>
	        </div>
        )
    }
}

const mapStateToProps = ({requestApiLoader=false}) => {
    return { requestApiLoader };
};
  
const mapDispatchToProps = (dispatch:any) => ({
    setApiLoader: () => dispatch(setApiLoader({}))
});

export default connect(mapStateToProps, mapDispatchToProps)(Signup);

interface signupFormValues { 
    distributor_name:string;
    street:string;
    distributor_website:string;
    city:string;
    state:string;
    zipcode:any;
    app_suite:string;
    first_name:string;
    last_name:string;
    phone_no:any;
    email:string;
}

interface IProps {
    history:History;
    setApiLoader():void;
    requestApiLoader:boolean;
}
  
interface IState {
    error : boolean,
    variant : string,
    message : string
}