import React from "react";
import { History } from 'history';
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

class ForgetPassword extends React.Component<IProps, IState>{
    constructor(props:IProps){
        super(props);
        this.state = {
            phone_no : ''
        }
    }
    setformatPhoneNumber=(phoneNumber:string,setFieldValue:any) => {
        var cleaned = ('' + phoneNumber).replace(/\D/g, '');
        var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        if(match){
          setFieldValue('phone_no', match[1] + '-' + match[2] + '-' + match[3]);
        }       
    }
    render(){
        const validate = Yup.object({
            phone_no:Yup.string().matches(/^[0-9]{3}-[0-9]{3}-[0-9]{4}$/, "Phone Number is invalid.")
            .min(10,'Please enter atleast 10 characters.')
            .required("Phone number is required.")
        });
        const initialValues: {phone_no:string} = {
              phone_no:''
        }
        return(
            <div className="buuz_login_wrapper">
                <div className="buzz_login_outer forgot_password_outer">
                    <div className="text-center">
                        {/* <img className="form_logo" src="/images/logo.png" alt="" /> */}
                    </div>
                    <div className="buuz_login_block">
                        <h1>Distributor Portal</h1>
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validate}
                            onSubmit={(values, actions) => {
                                
                            }}
                        >
                        {({
                            setFieldValue,
                            values,
                            handleChange
                            /* and other goodies */
                        }) => (
                        <Form className="row">
                            <div className="register_inner_heading col-12 text-center">
                                <h5 className="mt-0 mb-3">Distributor Details</h5>
                                <p className="mb-4">Enter your registered phone number below to receive password reset instruction.</p>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group">
                                    <Field type="text" name="phone_no" className="form-control" placeholder="Phone Number" onKeyDown={(e:any)=>{handleChange(e); this.setformatPhoneNumber(values.phone_no,setFieldValue)}} />
                                    <div className="form_control_icon">
                                        <img src="/images/phone-number.png" alt="" />
                                    </div>
                                    <ErrorMessage name="phone_no" component="span" className="text-danger"/>
                                </div>
                            </div>
                            <div className="col-12 text-center my-2">
                                <p>Remember Password ? <b><a className="text-dark" onClick={()=>this.props.history.push('/')}>Login</a></b></p>
                            </div>
                            <div className="col-12 text-center">
                                <div className="login_btn_wrapper">
                                    <button type="submit" className="btn btn-primary"><img src="assets/images/login.png" alt="" /> Send Request</button>
                                </div>
                            </div>
                        </Form>
                         )}
                        </Formik>
                    </div>
                </div>
            </div>
        )
    }
}

export default ForgetPassword;

interface IProps {
    history:History;
    
}
  
interface IState {
   phone_no:string;
}