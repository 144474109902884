import * as React from "react";
import { connect } from 'react-redux';
import {setNavBarStyle,setHeaderTitle} from '../../redux/actions/distributor';
import { NavLink } from 'react-router-dom';

class SideBar extends React.Component<IProps,IState> {
    constructor(props:IProps){
        super(props);
    }
   closeNav=()=>{
       this.props.setNavBarStyle({left:"-100%"})
   }
   SetHeaderTitle=(header_title:string)=>{
    this.props.setHeaderTitle({header_title})
   }
   render(){
       const navbar = {
           left : this.props.left
       }
       return(
         <div className="buuz_left_menu" id="mobile_adminnav" style={navbar}>
            <span className="close_btn d-block d-xl-none" onClick={this.closeNav}>
            <img src="/images/close.png" alt="" width="22" /></span>
            <div className="side_bar_logo text-center">
                {/* <img src="/images/logo.png" alt="" /> */}
            </div>
            <div className="left_link">
                <ul className="mb-auto">
                    <li><NavLink to="/dashboard" activeClassName="active" onClick={()=>{this.SetHeaderTitle('Distributor Profile')}}>
                        <img className="active_off" src="/images/distribution_profile_off.png" alt="" /> 
                        <img className="active_on" src="/images/distribution_profile_on.png" alt="" /> 
                        Distributor Profile
                    </NavLink></li>
                    <li><NavLink to="/distribution_coverage" activeClassName="active" onClick={()=>{this.SetHeaderTitle('Distributor Coverage')}}>
                        <img className="active_off" src="/images/distribution_coverage_off.png" alt="" /> 
                        <img className="active_on" src="/images/distribution_coverage_on.png" alt="" /> 
                        Distribution Coverage
                    </NavLink></li>
                    <li><NavLink to="/portfolio" activeClassName="active" onClick={()=>{this.SetHeaderTitle('Portfolio')}}>
                        <img className="active_off" src="/images/porfolio_off.png" alt="" /> 
                        <img className="active_on" src="/images/porfolio_on.png" alt="" /> 
                        Portfolio
                    </NavLink></li>
                    <li><NavLink to="/special" activeClassName="active" onClick={()=>{this.SetHeaderTitle('Specials & New Release')}}>
                        <img className="active_off" src="/images/special_releases_off.png" alt="" /> 
                        <img className="active_on" src="/images/special_releases_on.png" alt="" /> 
                        Specials & New Releases
                    </NavLink></li>
                </ul>
                <ul className="left_bottom_link">
                    <li><NavLink to="/leads" activeClassName="active" onClick={()=>{this.SetHeaderTitle('BUUZ Connect Leads')}}>BUUZ Connect Leads</NavLink></li>
                    <li><NavLink to="/orders" activeClassName="active" onClick={()=>{this.SetHeaderTitle('BUUZ Connect Orders')}}>BUUZ Connect Orders</NavLink></li>
                    <li><NavLink to="/contact_us" activeClassName="active" onClick={()=>{this.SetHeaderTitle('Contact Us')}}>Contact Us</NavLink></li>
                </ul>
            </div>
        </div>
      )
   }
}

const mapStateToProps = (state:any) => {
    return { left:state.left };
};
  
const mapDispatchToProps = (dispatch:any) => ({
    setNavBarStyle: (payload:{left:string}) => dispatch(setNavBarStyle(payload)),
    setHeaderTitle : (payload:{header_title:string}) => dispatch(setHeaderTitle(payload))
});
  
export default connect(mapStateToProps, mapDispatchToProps)(SideBar);

interface IProps{
   left : string,
   setNavBarStyle(payload:{left:string}):void;
   setHeaderTitle(payload:{header_title:string}):void;
}
interface IState{}