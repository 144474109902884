import React from "react";
import { connect } from "react-redux";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Alert } from "react-bootstrap";
import * as Yup from "yup";
import axios from "axios";
import constants from "../constants";
import { setApiLoader } from "../redux/actions/distributor";

class Dashboard extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      showAlert: false,
      variant: "",
      message: "",
      userProfileData: {
        first_name: "",
        last_name: "",
        primary_contact_name: "",
        primary_email_address: "",
        primary_phone_no: "",
        phone_no: "",
        street: "",
        city: "",
        state: "",
        zipcode: "",
        app_suite: "",
        email: "",
      },
    };
  }
  componentDidMount = () => {
    this.getProfile();
  };

  hideAlert = () => {
    setTimeout(() => {
      this.setState((prevState) => ({
        showAlert: !prevState.showAlert,
      }));
    }, 5000);
  };

  getProfile = async () => {
    try {
      this.props.setApiLoader(); //set Api loader
      let url: string = `${constants.BASE_API_URL}user/getProfile`;
      const response = await axios.get(url, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      });
      this.setState({
        userProfileData: response.data.data,
      });
      let user_data = JSON.stringify(response.data.data);
      localStorage.setItem("user_data", user_data);
      let primary_phone = this.formatPhoneNumber(
        this.state.userProfileData.primary_phone_no
      );
      let phone = this.formatPhoneNumber(this.state.userProfileData.phone_no);
      let userProfileData = { ...this.state.userProfileData };
      userProfileData.phone_no = phone || this.state.userProfileData.phone_no;
      userProfileData.primary_phone_no =
        primary_phone || this.state.userProfileData.primary_phone_no;
      this.setState({
        userProfileData,
      });
      this.props.setApiLoader(); //set Api loader
    } catch (error) {
      this.props.setApiLoader(); //set Api loader
      this.setState((prevState) => ({
        showAlert: !prevState.showAlert,
        variant: "danger",
        message: error.response.data.message,
      }));

      setTimeout(() => {
        this.setState((prevState) => ({
          showAlert: !prevState.showAlert,
        }));
      }, 4000);
    }
  };
  updateProfile = async (data: profileForm) => {
    try {
      this.props.setApiLoader(); //set Api loader
      let url: string = `${constants.BASE_API_URL}user/updateProfile`;
      data.phone_no = ("" + data.phone_no).replace(/\D/g, "");
      data.primary_phone_no = ("" + data.primary_phone_no).replace(/\D/g, "");
      const response = await axios.post(url, data, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      });
      this.props.setApiLoader(); //set Api loader
      this.setState((prevState) => ({
        showAlert: !prevState.showAlert,
        variant: "success",
        message: response.data.message,
      }));
      this.getProfile();
      this.hideAlert();
    } catch (error) {
      this.props.setApiLoader(); //set Api loader
      this.setState((prevState) => ({
        showAlert: !prevState.showAlert,
        variant: "danger",
        message: error.response.data.message,
      }));

      setTimeout(() => {
        this.setState((prevState) => ({
          showAlert: !prevState.showAlert,
        }));
      }, 4000);
    }
  };
  setformatPhoneNumber = (
    phoneNumber: string,
    setFieldValue: any,
    field: string
  ) => {
    var cleaned = ("" + phoneNumber).replace(/\D/g, "");
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      setFieldValue(field, match[1] + "-" + match[2] + "-" + match[3]);
    }
  };

  formatPhoneNumber = (phoneNumberString: string) => {
    var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return match[1] + "-" + match[2] + "-" + match[3];
    }
    return "";
  };
  setPrimaryNumber = (e: any, setFieldValue: any) => {
    if (e.target.checked) {
      let val = this.formatPhoneNumber(this.state.userProfileData.phone_no);
      setFieldValue("primary_phone_no", val);
    } else {
      let new_val = this.formatPhoneNumber(
        this.state.userProfileData.primary_phone_no
      );
      setFieldValue("primary_phone_no", new_val);
    }
  };
  setPrimaryName = (e: any, setFieldValue: any) => {
    if (e.target.checked) {
      setFieldValue(
        "primary_contact_name",
        `${this.state.userProfileData.first_name} ${this.state.userProfileData.last_name}`
      );
    } else {
      setFieldValue(
        "primary_contact_name",
        this.state.userProfileData.primary_contact_name
      );
    }
  };

  render() {
    const validate = Yup.object({
      first_name: Yup.string()
        .min(4, "First name  must be at least 4 characters.")
        .required("Please enter the first name."),
      last_name: Yup.string()
        .min(4, "First name  must be at least 4 characters.")
        .required("Please enter the first contact name."),
      email: Yup.string().email("Email is invalid."),
      primary_contact_name: Yup.string()
        .min(4, "Primary contact name  must be at least 4 characters.")
        .required("Please enter the primary contact name."),
      primary_phone_no: Yup.string()
        .matches(/^[0-9]{3}-[0-9]{3}-[0-9]{4}$/, "Contact number is not valid.")
        .min(10, "Primary contact number is invalid.")
        .required("Please enter the primary contact number."),
      primary_email_address: Yup.string()
        .email("Email is invalid.")
        .required("Primary email is required."),
      street: Yup.string().required("Street is required."),
      city: Yup.string().required("City is required."),
      state: Yup.string().required("State is required."),
      zipcode: Yup.string()
        .matches(/^[0-9]+$/, "Must be only digits.")
        .min(5)
        .required("Zipcode is required."),
      app_suite: Yup.string(),
      phone_no: Yup.string()
        .matches(/^[0-9]{3}-[0-9]{3}-[0-9]{4}$/, "Office number is not valid.")
        .min(10, "Office number is invalid")
        .required("Office number is required."),
    });
    const initialValues: profileForm = {
      first_name: this.state.userProfileData.first_name,
      last_name: this.state.userProfileData.last_name,
      email: this.state.userProfileData.email,
      primary_contact_name: this.state.userProfileData.primary_contact_name
        ? this.state.userProfileData.primary_contact_name
        : "",
      primary_email_address: this.state.userProfileData.primary_email_address
        ? this.state.userProfileData.primary_email_address
        : this.state.userProfileData.email,
      primary_phone_no: this.state.userProfileData.primary_phone_no
        ? this.state.userProfileData.primary_phone_no
        : "",
      phone_no: this.state.userProfileData.phone_no
        ? this.state.userProfileData.phone_no
        : "",
      street: this.state.userProfileData.street,
      state: this.state.userProfileData.state,
      zipcode: this.state.userProfileData.zipcode,
      app_suite: this.state.userProfileData.app_suite
        ? this.state.userProfileData.app_suite
        : "",
      city: this.state.userProfileData.city,
    };
    return (
      <div className="buuz_right_outer">
        {/* <div className="buuz_inner_heading">
              <h1>MARA Imports</h1>
            </div> */}
        <div className="buuz_connectus_wrapper">
          {this.state.showAlert && (
            <Alert variant={this.state.variant}>{this.state.message}</Alert>
          )}
          <Formik
            initialValues={initialValues}
            validationSchema={validate}
            enableReinitialize
            onSubmit={(values, actions) => {
              this.updateProfile(values);
            }}
          >
            {({
              setFieldValue,
              values,
              handleChange,
              /* and other goodies */
            }) => (
              <Form>
                <div className="row">
                  <div className="form-group col-md-6">
                    <Field type="text" name="first_name" className="form-control" placeholder="First Name"/>
                    <ErrorMessage name="first_name" component="span" className="text-danger"/>
                  </div>
                  <div className="form-group col-md-6">
                    <Field type="text" name="last_name" className="form-control" placeholder="Last Name"/>
                    <ErrorMessage name="last_name" component="span" className="text-danger"/>
                  </div>
                  <div className="form-group col-md-6">
                    <Field type="text" name="email" className="form-control" placeholder="Email" readOnly/>
                  </div>
                  <div className="form-group col-md-6">
                    <Field type="text" name="phone_no" className="form-control" placeholder="Office Number"
                      onKeyDown={(e: any) => {
                        handleChange(e);
                        this.setformatPhoneNumber(
                          values.phone_no,
                          setFieldValue,
                          "phone_no"
                        );
                      }}
                    />
                    <ErrorMessage name="phone_no" component="span" className="text-danger"/>
                  </div>
                  <div className="form-group col-md-12">
                    <input type="checkbox" className="mr-2" onChange={($e: any) => this.setPrimaryName($e, setFieldValue)}/>
                    Same as main contact.
                  </div>
                  <div className="form-group col-md-6">
                    <Field type="text" name="primary_contact_name" className="form-control" placeholder="Primary Contact Name"/>
                    <ErrorMessage name="primary_contact_name" component="span" className="text-danger"/>
                  </div>
                  <div className="form-group col-md-6">
                    <Field type="email" name="primary_email_address" className="form-control" placeholder="Primary Email Address"/>
                    <ErrorMessage name="primary_email_address" component="span" className="text-danger"/>
                  </div>
                  <div className="form-group col-md-12">
                    <input type="checkbox" className="mr-2" onChange={($e: any) => this.setPrimaryNumber($e, setFieldValue)} />
                     Same as Phone number.
                  </div>
                  <div className="form-group col-md-6">
                    <Field type="text" name="primary_phone_no" className="form-control" placeholder="Primary Contact Phone Number"
                      onKeyDown={(e: any) => {
                        handleChange(e);
                        this.setformatPhoneNumber(
                          values.primary_phone_no,
                          setFieldValue,
                          "primary_phone_no"
                        );
                      }}
                    />
                    <ErrorMessage name="primary_phone_no" component="span" className="text-danger"/>
                  </div>
                  <div className="form-group col-md-12">
                    Other Information
                  </div>
                  <div className="form-group col-sm-6">
                    <Field type="text" name="street" className="form-control" placeholder="Street Address"/>
                    <ErrorMessage name="street" component="span" className="text-danger"/>
                  </div>
                  <div className="form-group col-sm-6">
                    <Field type="text" name="app_suite" className="form-control" placeholder="Suite/Apt" />
                    <ErrorMessage name="app_suite" component="span" className="text-danger"/>
                  </div>
                  <div className="form-group col-md-6">
                    <Field type="text" name="city" className="form-control" placeholder="City"/>
                    <ErrorMessage name="city" component="span" className="text-danger"/>
                  </div>
                  <div className="form-group col-sm-6">
                    <Field type="text" name="state" className="form-control" placeholder="State"
                      onKeyUp={(e: any) => {
                        handleChange(e);
                        setFieldValue("state", values.state.toUpperCase());
                      }}
                    />
                    <ErrorMessage name="state" component="span" className="text-danger"/>
                  </div>
                  <div className="form-group col-sm-6">
                    <Field type="text" name="zipcode" className="form-control" placeholder="Zipcode"/>
                    <ErrorMessage name="zipcode" component="span" className="text-danger"/>
                  </div>
                  <div className="form-group col-md-12">
                    <button type="submit" className="btn btn-primary">
                      Save
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return { state };
};

const mapDispatchToProps = (dispatch: any) => ({
  setApiLoader: () => dispatch(setApiLoader({})),
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);

interface IProps {
  setApiLoader(): void;
}

interface IState {
  showAlert: boolean;
  variant: string;
  message: string;
  userProfileData: profileForm;
}

interface profileForm {
  first_name: string;
  last_name: string;
  email: string;
  primary_contact_name: string;
  primary_email_address: string;
  primary_phone_no: string;
  phone_no: string;
  street: string;
  city: string;
  state: string;
  zipcode: string;
  app_suite: string;
}
