// SET_AUTH
export const setAuth = (payload: any) => ({
  type: "SET_AUTH",
  payload,
});

export const setNavBarStyle = (payload:any)=>({
  type:"SET_NAVBAR_STYLE",
  payload
})

export const setApiLoader = (payload:any)=>({
  type:"SET_LOADER",
  payload
});

export const setUserData = (payload:any)=>({
  type:"SET_USER_DATA",
  payload
});

export const setHeaderTitle = (payload:any)=>({
  type:"SET_HEADER_TITLE",
  payload
});
