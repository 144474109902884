import React from "react";
import { History } from 'history';
import { connect } from 'react-redux';
import axios from "axios";
import constants from "../../constants";
import { setApiLoader } from  '../../redux/actions/distributor';
import moment from 'moment';

class OrderDetails extends React.Component<IProps,IState>{
    constructor(props:IProps){
        super(props);
        this.state={
            orders_details : {},
            json_data:{},
            status:1
        }
    }
    componentDidMount = () =>{
        this.getSpecialReleaseOrder();
    }
    getSpecialReleaseOrder = async() =>{
        try {
            let _url:any = window.location.href;
            let paramString = _url.split('/');
            this.props.setApiLoader(); //set Api loader
            let url:string = `${constants.BASE_API_URL}orders/get_orders_details/${paramString[4]}`;
            const response = await axios.get(url,{headers:{ 'Authorization' : `Bearer ${localStorage.getItem('token')}` }});
            const json_data = JSON.parse(response.data.data.requestDetails[0].json);
            this.setState({
                orders_details : {...response.data.data.requestDetails[0]},
                json_data : {...json_data},
                status : response.data.data.requestDetails[0].status+1
            });
           // console.log("data",this.state.orders_details)         
            this.props.setApiLoader(); //set Api loader
          } catch (error) {
            this.props.setApiLoader(); //set Api loader
            
          }
    }
    updateSpecialReleaseOrder = async() =>{
        try {
            this.props.setApiLoader(); //set Api loader
            let url:string = `${constants.BASE_API_URL}orders/update_order_status/${this.state.orders_details.id}/${this.state.status}`;
            const response = await axios.get(url,{headers:{ 'Authorization' : `Bearer ${localStorage.getItem('token')}` }});
            this.getSpecialReleaseOrder();//update status values         
            this.props.setApiLoader(); //set Api loader
          } catch (error) {
            this.props.setApiLoader(); //set Api loader            
          }
    }
    changeStatus = ($e:any) =>{
       this.setState({
           status : $e.target.value
       })
    }
    render(){
        const selectOptions = [];
        const status = (this.state.orders_details.status !== 3) ? this.state.orders_details.status + 1 : this.state.orders_details.status; 
        for(let i=status; i<=3; i++){
            if(i===0){
              selectOptions.push({value:i,name:'New Order'});               
            }else if(i===1){
              selectOptions.push({value:i,name:'Order In Process'}); 
            }else if(i===2){
              selectOptions.push({value:i,name:'Order Out for Delivery'});  
            }else if(i===3){
              selectOptions.push({value:i,name:'Order Completed'});   
            }
        }
        return(
            <div className="buuz_right_outer">
                <div className="buuz_inner_heading">
                    <h1>Buuz Connect Order</h1>
                    <div className="right_heading_text">
                        <div><b>Lead Date : </b> {moment(this.state.orders_details.date_added).format('MM/DD/YYYY')}</div>
                        <div><b>Time : </b> {moment(this.state.orders_details.date_added).format('hh:mm A')}</div>
                        <div className="print_icon"><b>Print </b> <i className="fa fa-print" aria-hidden="true" onClick={() => window.print()}></i></div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="buuz_connect_status_block">
                            <h5>Brand Name :</h5>
                            <p>{this.state.json_data.brand}</p>
                            <h5>Product :</h5>
                            <p>{this.state.json_data.special_name}</p>
                            <h5>Quantity :</h5>
                            <p>{this.state.json_data.size_qty} {this.state.json_data.size_type}</p>
                            <h5>Regular Price :</h5>
                            <p>${this.state.json_data.regular_price} / {this.state.json_data.size_type}</p>
                            <h5>Special Price :</h5>
                            <p>${this.state.json_data.special_price} / {this.state.json_data.size_type}</p>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="buuz_connect_status_block">
                            <h5>Account :</h5>
                            <p>{this.state.orders_details.owner_name}</p>
                            <h5>Info Requested by :</h5>
                            <p>{this.state.orders_details.bar_first_name} {this.state.orders_details.bar_last_name}</p>
                            <h5>Account Address :</h5>
                            <p>{this.state.orders_details.address}</p>
                            <h5>Phone Number :</h5>
                            <p>{this.state.orders_details.phone}</p>
                        </div>
                    </div>
                    <div className="col-lg-12 mt-4">
                        <form className="form-inline">
                        <div className="form-group mb-2 mr-3 mr-sm-0">
                            <label><b>Status : </b></label>
                        </div>
                        <div className="form-group mx-sm-3 mb-2">
                            <select className="form-control lead_received" value={this.state.status} onChange={(e:any)=>this.changeStatus(e)}>
                               {selectOptions.map((data:any,i:number)=>(
                                   <option key={i} value={data.value}>{data.name}</option>
                               ))}
                            </select>
                        </div>
                        <button type="button" className="btn btn-primary mb-2" onClick={()=>this.updateSpecialReleaseOrder()} >Save & Send Status</button>
                        </form>
                    </div>
                </div>
                </div>
        )
    }
}

const mapStateToProps = (state: any) => {
    return { state };
};
  
const mapDispatchToProps = (dispatch: any) => ({
    setApiLoader: () => dispatch(setApiLoader({})),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderDetails);

interface IProps{
    history: History;
    setApiLoader(): void;
}

interface IState{
    orders_details : any;
    json_data: any;
    status : number;
}