import React from 'react';
import AppRouter, { history } from '../routers/AppRoutes';
import { Provider } from 'react-redux';
import configureStore from '../redux/store/configureStore';
import jwt from 'jsonwebtoken';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/style.css';

const store = configureStore();

function App() {
  //check token is expired or not if user is already logged In
  if(localStorage.getItem('token')){ 
    let token = localStorage.getItem('token') || 'token';
    let decoded:any = jwt.decode(token);
    if (decoded.exp < new Date().getTime()/1000) {
      localStorage.removeItem('token');
      history.push('/');
    }
  }
  return (
    <Provider store={store}>
      <AppRouter />
    </Provider>
  );
}

export default App;
