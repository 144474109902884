import * as React from "react";

export class Loader extends React.Component{
    render(){
        return(
            <div id="buuz_loader_wrapper"> 
                 <div id="move_loader"></div>
            </div>
        )
    }
}