import React from "react";
import { Link } from 'react-router-dom';

const NotFoundPage = () => (
    <div className="container text-center p-5">
        <article>
            <h1>We&rsquo;ll be back soon!</h1>
            <div>
                <p>We are under maintenance
                    We are working on getting back up and running asap
                    Please check back</p>
                <p>&mdash; Buuz Connect Team</p>
                404 - <Link to="/">Go home</Link>
            </div>
        </article>
    </div>
);

export default NotFoundPage;