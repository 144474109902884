import React from "react";
import { Route, Router, Switch } from "react-router-dom";
import Login from "../components/login";
import { createBrowserHistory } from "history";
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import  Dashboard  from "../components/dashboard";
import NotFoundPage from "../components/NotFound";
import Signup from "../components/Signup";
import Portfolio from "../components/portfolio/Portfolio";
import ContactUs from "../components/ContactUs";
//import AddPortfolio from "../components/portfolio/AddPortfolio";
import SpecialsList from "../components/special/SpecialsList";
import AddSpecial from "../components/special/AddSpecial";
import EditSpecial from "../components/special/EditSpecial";
import BerrList from "../components/portfolio/BerrList";
import ForgetPassword from "../components/ForgetPassword";
import LeadsOrders from "../components/orders/LeadsOrders";
import LeadsDetails from "../components/orders/LeadsDetails";
import Orders from "../components/orders/Orders";
import OrderDetails from "../components/orders/OrderDetails";
import ViewPortfolio from "../components/portfolio/ViewPortfolio";
import Coverage from "../components/Coverage";

export const history = createBrowserHistory();

const AppRouter = () => (
  <Router history={history}>
    <div>
      <Switch>        
        <PublicRoute path='/' component={Login} exact={true}/>
        <PublicRoute path='/signup' component={Signup} exact />
        <PublicRoute path='/forget_password' component={ForgetPassword} exact />
        <PrivateRoute path="/dashboard" component={Dashboard} />
        <PrivateRoute path='/portfolio' component={Portfolio} />
        <PrivateRoute path='/add_portfolio' component={BerrList} />
        <PrivateRoute path='/contact_us' component={ContactUs} />
        <PrivateRoute path='/special' component={SpecialsList} />
        <PrivateRoute path='/add_special' component={AddSpecial} />
        <PrivateRoute path='/edit_special_release/:id' component={EditSpecial} />
        <PrivateRoute path='/leads' component={LeadsOrders} />
        <PrivateRoute path='/leads_details/:id' component={LeadsDetails} />
        <PrivateRoute path='/orders' component={Orders} />
        <PrivateRoute path='/order_details/:id' component={OrderDetails} />
        <PrivateRoute path='/view_portfolio/:id' component={ViewPortfolio} />
        <PrivateRoute path='/distribution_coverage' component={Coverage} />
        <Route component={NotFoundPage} />
      </Switch>
    </div>
  </Router>
);

export default AppRouter;