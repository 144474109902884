import React from "react";
import { History } from 'history';
import { connect } from 'react-redux';
//import { Alert } from 'react-bootstrap';
import axios from "axios";
import constants from "../../constants";
import { setApiLoader } from  '../../redux/actions/distributor';
import ReactPaginate from 'react-paginate';
import moment from 'moment';

class Orders extends React.Component<IProps,IState> {

   constructor(props:IProps){
      super(props);
      this.state={
          orders:[],
          totalPages:1,
          from_date : '',
          to_date : '',
          error : '',
          search : ''
      }
   }
   handlePageClick = (data:any) =>{
    let filter = {
      page: parseInt(data.selected)+1,
      limit: "10"
    };
    this.getSpecialRequest(filter);
   }

  componentDidMount = () => {
    let data = {
      page: "1",
      limit: "10",
    };
    this.getSpecialRequest(data);
  };
  getSpecialRequest = async (data: any) => {
    try {
      this.props.setApiLoader(); //set Api loader
      let url: string = `${constants.BASE_API_URL}orders/get_orders?page=${data.page}&limit=${data.limit}&from_date=${this.state.from_date}&to_date=${this.state.to_date}&search=${this.state.search}`;
      const response = await axios.get(url, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      });
      this.props.setApiLoader(); //set Api loader
      this.setState({
          orders : [...response.data.data.orders],
          totalPages : response.data.data.totalPages
      })

      //console.log(this.state.orders);
      //console.log(JSON.parse(response.data.data.orders[0].json));
    } catch (error) {
      this.props.setApiLoader(); //set Api loader
    }
  };

  checkOrderDates = () =>{
    if(this.state.to_date < this.state.from_date){
      this.setState({error:"from date must be greater then to date."});
      return false;
    }
    if(this.state.to_date === '' || this.state.from_date === ''){
      this.setState({error:"Both fields are required."});
      return false;
    }
    this.setState({
      error:'',
      from_date : moment(this.state.from_date).format('YYYY-MM-DD'),
      to_date : moment(this.state.to_date).format('YYYY-MM-DD'),
      search : ''
    },()=>{
       this.getSpecialRequest({page:'1',limit:'10'});       
    });
  }

  searchOrders = ($e:any)=>{
    this.setState({
      search : $e.target.value,
      from_date : '',
      to_date : ''
    },()=>{
      this.getSpecialRequest({page:'1',limit:'10'}); 
    })
  }

   render(){
     return (
        <div className="buuz_right_outer">
            <div className="buuz_connect_order_top buuz_inner_heading">
			  <form>
				<div className="form-row align-item-center">
          <div className="col-lg-6 d-flex first_field flow_wrap">
					  <div className="connect_lable">Search By :</div>
					  <div className="form-group mr-3">
						<select className="form-control">
						  <option>Account Name</option>
						</select>
					  </div>
					  <div className="form-group">
						  <input type="text" className="form-control" placeholder="Search" defaultValue={this.state.search} onChange={($e:any)=>this.searchOrders($e)} />
					  </div>
				  </div>
          <div className="col-lg-6 text-right mt-4">
						  <button type="button" className="btn btn-primary text-uppercase">Download Report</button>
          </div>				  
				  <div className="col-lg-12 d-flex align-items-center flow_wrap mt-4">
					  <div className="connect_lable">Search By date :</div> 
					  <div className="form-group mr-2">
					  	<label className="mb-0"><b>From</b></label>
					  </div>
					  <div className="form-group">
						  <input type="date" className="form-control date_control" defaultValue={this.state.from_date} onChange={($event:any) => this.setState({from_date:$event.target.value})}  placeholder="dd-mm-year" />
					  </div>
					  <div className="form-group mx-2">
						<label className="mb-0"><b>To</b></label>
					  </div>
					  <div className="form-group">
						  <input type="date" className="form-control date_control" defaultValue={this.state.to_date} onChange={($event:any) => this.setState({to_date:$event.target.value})} placeholder="dd-mm-year" />
					  </div>
					  <div className="form-group">
              <button type="button" className="btn btn-primary text-uppercase" onClick={this.checkOrderDates}>Search</button>
					  </div>
				  </div>
          <div className="col-md-12">
             <p className="text-danger text-capitalize">{this.state.error}</p>
          </div>
				</div>
			 </form>
		    </div>
          <div className="portfolio_wrapper">
            {/* {this.state.showAlert && <Alert variant={this.state.variant}>{this.state.message}</Alert> } */}
  
            <div className="tab-content">
              <div id="all" className="tab-pane active">
                <div className="table-responsive portfolio_table">
                <table className="table">
                    <thead>
                      <tr>
                        <th>Account Name</th>
                        <th>Email</th>
                        <th>Phone</th>
                        <th>Special Name</th>
                        <th>Image</th>
                        {/* <th className="profolio_dis_td" style={{ width: "300" }}>
                          Brand
                        </th> */}
                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.orders.length > 0 &&
                        this.state.orders.map((data: any) => {
                        const json = JSON.parse(data.json);
                          return(
                          <tr key={data.id}>
                            <td>{data.owner_name}</td>
                            <td>{data.email}</td>
                            <td>{data.phone}</td>
                            <td>{json.special_name}</td>
                            <td>
                              {json.category === "1" &&  <img src={(json.logo != '') ?  'https://buuzapp.com/upload/wine_orig/'+json.logo : json.main_image} alt="brand img" width="70"/>}
                              {json.category === "2" && <img src={(json.logo != '') ? 'https://buuzapp.com/upload/liquor_orig/'+json.logo : json.main_image} alt="brand img" width="70"/>}
                              {json.category === "3" && <img src={(json.logo != '') ? 'https://buuzapp.com/upload/beer_orig/'+json.logo : json.main_image} alt="brand img" width="70"/>}
                              {json.category === "4" && <img src={(json.image) ? json.image : '/images/logo.png' } alt="brand img" width="70"/>}
                            </td>
                            {/* <td className="profolio_dis_td" width={300}>
                              <p>{json.brand}</p>
                            </td> */}
                            <td className="status_btn">
                              {data.status === 0 && 'New Order'}
                              {data.status === 1 && 'Order In Process'}
                              {data.status === 2 && 'Order Out for Delivery'}
                              {data.status === 3 && 'Order Completed'}
                            </td>
                            <td className="action_btn">
                              <div className="d-flex">
                                <span className="portfolio_edit" onClick={()=>this.props.history.push(`/order_details/${data.id}`)}>
                                  <i className="fa fa-pencil" aria-hidden="true"></i>
                                </span>
                              </div>
                            </td>
                          </tr>
                        )})}
                        {this.state.orders.length === 0 && <tr><td align="center" colSpan={7}>No data found.</td></tr>}
                    </tbody>
                  </table>
                </div>
                <div
                  className="mt-4 text-right d-flex"
                  style={{ justifyContent: "flex-end" }}
                >
                  <ReactPaginate
                    previousLabel={"previous"}
                    nextLabel={"next"}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={this.state.totalPages}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={this.handlePageClick}
                    containerClassName={"pagination"}
                    activeClassName={"active"}
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      );
   }
}

const mapStateToProps = (state: any) => {
    return { state };
};
  
const mapDispatchToProps = (dispatch: any) => ({
    setApiLoader: () => dispatch(setApiLoader({})),
});

export default connect(mapStateToProps, mapDispatchToProps)(Orders);

interface IProps{
    history: History;
    setApiLoader(): void;
}

interface IState{
    orders : any,
    totalPages: number;
    from_date : string;
    to_date  : string;
    error :  string;
    search : string;
}