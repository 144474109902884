import React from "react";
import { History } from 'history';
import { connect } from 'react-redux';
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Alert } from 'react-bootstrap';
import axios from "axios";
import constants from "../../constants";
import { setApiLoader } from  '../../redux/actions/distributor';
import Select from 'react-select';

class EditSpecial extends React.Component<IProps,IState>{
    constructor(props:IProps){
        super(props);
        this.state = {
            showAlert : false,
            variant : '',
            message : '',
            brand : '',
            states : [],
            brand_images_array : [],
            portfolio_product : {
                id:"",
                name: "",
                image_1: "",
                image_2:"",
                quantity: 0,
                bottle_price: "",
                case_price: "",
                brand: "",
            },
            userReleaseData: {
                category:"",
                name : '',
                brand : '',
                description : '',
                country : 'US',
                state : [],
                start_date:'',
                end_date: '',
                bottle : '',
                regular_price:'',
                special_price:'',
                image_1 : '',
                discount : '',
                status : '',
                size : '',
                main_image :'',
                category_number : ''
            }
        }
    }
    hideAlert = ()=>{
        setTimeout(()=>{
            this.setState(prevState =>({
              showAlert : !prevState.showAlert
            }))
          },4000);
    }
    getStates = async() => {
        try{
          this.props.setApiLoader(); //set Api loader
          let url: string = `${constants.BASE_API_URL}protfolio/get_states`;
          const response = await axios.get(url, {
            headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
          });
          this.props.setApiLoader(); //set Api loader
          let state_array:Array<state_details> = [];
          response.data.data.forEach((data:any)=>{
              state_array.push({
                value: data.state_id, label: data.state_name
              });
          });
          this.setState({
            states : state_array
          },()=>{
              this.getSpecialReleaseProduct();
          });
        } catch (error) {
          this.props.setApiLoader(); //set Api loader
          this.setState((prevState) => ({
            showAlert: !prevState.showAlert,
            variant: "danger",
            message: error.response.data.message,
          }));
          this.hideAlert();
        }
    }

    componentDidMount = () =>{
        this.getStates();//get state of the Usa country
    }

    getSpecialReleaseProduct = async() =>{
        try {
            let _url:string = window.location.href;
            let paramString = _url.split('/');
            this.props.setApiLoader(); //set Api loader
            let url:string = `${constants.BASE_API_URL}protfolio/edit_special_release/${paramString[4]}`;
            const response = await axios.get(url,{headers:{ 'Authorization' : `Bearer ${localStorage.getItem('token')}` }});
            let states:Array<string> = response.data.data.products[0].state.split(',');
            const filter_state:Array<state_details> = this.state.states.filter((n:state_details) => {
                return states.indexOf(n.value.toString()) !== -1;
            });
            //set category according to their id
            let category;
            if(response.data.data.products[0].category === 1){
                category = 'Wine';
            }else if(response.data.data.products[0].category === 2){
                category = 'Liquor';
            }else if(response.data.data.products[0].category === 3){
                category = 'Beer';
            }else{
                category = 'Other Special';
            }
            this.setState({
                userReleaseData : {
                    category,
                    name : response.data.data.products[0].special_name,
                    brand : response.data.data.products[0].brand,
                    description : response.data.data.products[0].description,
                    country : 'US',
                    state : filter_state,
                    start_date:response.data.data.products[0].start_date,
                    end_date: response.data.data.products[0].end_date,
                    bottle : response.data.data.products[0].size_type,
                    regular_price:response.data.data.products[0].regular_price,
                    special_price:response.data.data.products[0].special_price,
                    image_1 : response.data.data.products[0].main_image,
                    discount : response.data.data.products[0].discount,
                    status : response.data.data.products[0].status,
                    size : response.data.data.products[0].size_qty,
                    main_image : response.data.data.products[0].image,
                    category_number : response.data.data.products[0].category
                },
                brand : response.data.data.products[0].brand
            });
            this.props.setApiLoader(); //set Api loader
            this.getBrand(response.data.data.products[0].portfolio_id);
          } catch (error) {
            this.props.setApiLoader(); //set Api loader
            this.setState(prevState => ({
                showAlert: !prevState.showAlert,
                variant : 'danger',
                message : "error.response.data.message"
              }));
      
              setTimeout(()=>{
                this.setState(prevState =>({
                  showAlert : !prevState.showAlert
                }))
              },4000);
          }
    }
    addProduct=async(val:productForm)=>{
        try {
            let _url:any = window.location.href;
            let paramString = _url.split('/');
            let formdata = new FormData();
            let stateString:any = [];
            val.state.forEach((data:state_details)=>{
                stateString.push(data.value)
            });
            stateString = stateString.join();
            formdata.append('category',this.state.userReleaseData.category_number);
            formdata.append('country',val.country);
            formdata.append('description',val.description);
            formdata.append('name',val.name);
            formdata.append('state',stateString);
            formdata.append('brand',this.state.brand);
            formdata.append('start_date',val.start_date);
            formdata.append('end_date',val.end_date);
            formdata.append('bottle',val.bottle);
            formdata.append('size',val.size);
            formdata.append('regular_price',val.regular_price);
            formdata.append('special_price',val.special_price);
            formdata.append('discount',val.discount); 
            formdata.append('status',val.status); 
            formdata.append('image_1',val.image_1);
            formdata.append('id',paramString[4]);
            formdata.append("brand_images", JSON.stringify(this.state.brand_images_array));
            formdata.append("portfolio_id", this.state.portfolio_product.id);

            this.props.setApiLoader(); //set Api loader
            let url:string = `${constants.BASE_API_URL}protfolio/edit_special_release`;
            const response = await axios.post(url,formdata,{headers:{ 'Authorization' : `Bearer ${localStorage.getItem('token')}` }});
            this.props.setApiLoader(); //set Api loader
            this.setState(prevState =>({
                showAlert: !prevState.showAlert,
                variant : 'success',
                message : response.data.message
            })); 
            this.hideAlert();
            this.props.history.push('/special');
        } catch (error) {
            this.props.setApiLoader(); //set Api loader
            this.setState(prevState => ({
                showAlert: !prevState.showAlert,
                variant : 'danger',
                message : error.response.data.message
              }));
            this.hideAlert();
        }

    }
    getBrand = async(id:string) => {
        try {
            this.props.setApiLoader(); //set Api loader
            let url:string = `${constants.BASE_API_URL}protfolio/get_portfolio_product_id/${id}`;
            const response = await axios.get(url,{headers:{ 'Authorization' : `Bearer ${localStorage.getItem('token')}`}});
            this.props.setApiLoader(); //set Api loader 
            let images = [];
            images.push(response.data.data.product[0].image_1);
            images.push(response.data.data.product[0].image_2);

            this.setState(({
                portfolio_product : response.data.data.product[0],
                brand_images_array : [...images]
            }));
        } catch (error) {
            this.props.setApiLoader(); //set Api loader 
            this.setState(({
                portfolio_product: {
                    id:"",
                    name: "",
                    image_1: "",
                    image_2:"",
                    quantity: 0,
                    bottle_price: "",
                    case_price: "",
                    brand: "",
                },
                brand_images_array : [""]
            }))
        }
    }
    addSelectValue = (data:any,setFieldValue:any)=>{
        setFieldValue('state',data);
        this.setState((prevState)=>({
            userReleaseData :{
                ...prevState.userReleaseData,
                state : data
            }
        }))
    }
    calculateDiscount = (values: any, setFieldValue: any) => {
        if (values.special_price !== "" && values.regular_price !== "") {
          let regular_price = parseFloat(values.regular_price);
          let val = regular_price - parseFloat(values.special_price);
          let dis = (val * 100) / regular_price;
          setFieldValue("discount", Math.round(dis));
        }
    };
    setRegularPrice = (e:any, setFieldValue: any) => {
        if(e.target.value === 'Cases'){
          setFieldValue("regular_price", this.state.portfolio_product.case_price);
        }
        if(e.target.value === 'Bottle'){
          setFieldValue("regular_price", this.state.portfolio_product.bottle_price);
        }
        let val = {
            regular_price : (e.target.value === 'Cases') ? this.state.portfolio_product.case_price : this.state.portfolio_product.bottle_price,
            special_price : this.state.userReleaseData.special_price
        }
        this.calculateDiscount(val,setFieldValue);
    }
    render(){
        const FILE_SIZE = 160 * 1024;
        const SUPPORTED_FORMATS = [
            "image/jpg",
            "image/jpeg",
            "image/png"
          ];
        const today = new Date();
        const yesterday = new Date(today);          
        yesterday.setDate(yesterday.getDate() - 1);
        const validate = Yup.object({
            category : Yup.string().required("Category is required."),
            brand : Yup.string().required("Brand is required."),
            description : Yup.string().required("Description is required."),
            name : Yup.string().required("Special name is required."),
            country : Yup.string().required("country is required."),
            state : Yup.array().min(1, 'Pick at least 1 state.'),
            start_date : Yup.date().min(yesterday,"Start date cannot be in the past.").required("Start date is required."),
            end_date : Yup.date().min(Yup.ref('start_date'),"End date cannot be in the past.").required("End date is required."),
            bottle : Yup.string().required("Bottle is required."),
            regular_price: Yup.string()
                                      .matches(/^[0-9]\d*(\.\d+)?$/, "Regular price is not valid.")
                                      .required("Regular price is required."),
            special_price: Yup.string()
                                       .matches(/^[0-9]\d*(\.\d+)?$/, "Special price is not valid.")
                                       .required("Special price is required."),
            discount : Yup.string().matches(/^[0-9]+$/, "Must be only digits.").required("Discount is required."),
            size : Yup.string().matches(/^[0-9]+$/, "Must be only digits.").required("Size is required."),
            status : Yup.string().required("Status is required."),
            image_1 : Yup.mixed()
                                 .test(
                                    "fileSize",
                                    "File too large",
                                    (value:any) => !value || (value && value.size <= FILE_SIZE)
                                  )
                                  .test(
                                    "fileFormat",
                                    "Unsupported Format",
                                    (value:any)  => !value || (value && SUPPORTED_FORMATS.includes(value.type))
                                  )
       });
       const initialValues : productForm = {
           category:this.state.userReleaseData.category,
           name : this.state.userReleaseData.name,
           brand : this.state.userReleaseData.brand,
           description : this.state.userReleaseData.description,
           country : 'US',
           state : this.state.userReleaseData.state,
           start_date:this.state.userReleaseData.start_date,
           end_date: this.state.userReleaseData.end_date,
           bottle : this.state.userReleaseData.bottle,
           regular_price:this.state.userReleaseData.regular_price,
           special_price:this.state.userReleaseData.special_price,
           image_1 : '',
           discount : this.state.userReleaseData.discount,
           status : this.state.userReleaseData.status,
           size : this.state.userReleaseData.size

       }
        return(
            <div className="buuz_right_outer">
                <div className="buuz_inner_heading">
                    <h1>Edit Specials & New Release</h1>
                </div>
                <div className="buuz_connectus_wrapper">
                   {this.state.showAlert && <Alert variant={this.state.variant}>{this.state.message}</Alert> }
                   <Formik initialValues={initialValues} validationSchema={validate} enableReinitialize
                        onSubmit={(values, actions) => {
                        if(this.state.brand_images_array.length === 0 && this.state.userReleaseData.main_image === ''){
                            actions.setFieldTouched('image_1',true);
                            actions.setFieldError('image_1',"image is required.");
                            actions.setSubmitting(false);
                            return;
                        }                    
                        this.addProduct(values);
                        actions.resetForm();
                      }}
                    >
                     {({
                        setFieldValue,
                        values,
                        handleChange
                        /* and other goodies */
                    }) => (
                    <Form>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <Field type="text" className="form-control" name="category" readOnly></Field>
                                    <ErrorMessage name="category" component="span" className="text-danger" />
                                </div>
                                <div className="form-group">
                                    <Field type="text" className="form-control" name="brand" placeholder="Enter Brand" value={this.state.brand} readOnly/>
                                    <ErrorMessage name="brand" component="span" className="text-danger" /> 
                                </div>
                                <div className="form-group">
                                    <Field as="textarea" className="form-control" name="description" rows={4} placeholder="Special Discription"></Field>
                                    <ErrorMessage name="description" component="span" className="text-danger" />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <Field type="text" name="name" className="form-control" placeholder="Special Name" />
                                    <ErrorMessage name="name" component="span" className="text-danger" />
                                </div>
                                <div className="uploaded_img">
                            {this.state.brand_images_array.length > 0 &&
                                this.state.brand_images_array.map(
                                (data: any, index: any) => (
                                    <div key={index}>
                                    {this.state.userReleaseData.category_number.toString() === "1" && (
                                        <img
                                        src={
                                            data
                                            ? `https://buuzapp.com/upload/wine_orig/${data}`
                                            : "/images/logo.png"
                                        }
                                        alt=""
                                        />
                                    )}
                                    {this.state.userReleaseData.category_number.toString() === "2" && (
                                        <img
                                        src={
                                            data
                                            ? `https://buuzapp.com/upload/liquor_orig/${data}`
                                            : "/images/logo.png"
                                        }
                                        alt=""
                                        />
                                    )}
                                    {this.state.userReleaseData.category_number.toString() === "3" && (
                                        <img
                                        src={
                                            data
                                            ? `https://buuzapp.com/upload/beer_orig/${data}`
                                            : "/images/logo.png"
                                        }
                                        alt=""
                                        />
                                    )}
                                    {this.state.userReleaseData.category_number.toString() === "4" && (
                                        <img src={data ? data : "/images/logo.png"} alt=""/>
                                      )}
                                   </div>
                                   )
                                )}
                                </div>
                                <div className="row">
                                    <div className="form-group col-6">
                                        <input type="file" name="image_1" className="form-control" placeholder="Images1" onChange={($e:any)=>setFieldValue('image_1',$e.target.files[0])} />
                                        <ErrorMessage name="image_1" component="span" className="text-danger" />
                                    </div>
                                    {this.state.userReleaseData.main_image !== '' &&
                                        <div className="col-6">
                                            <div className="uploaded_img">
                                            <img src={this.state.userReleaseData.main_image} height="50px" width="50px" />                                           
                                            </div>
                                        </div>
                                    }
                                </div>
                                <div className="form-group">
                                    <Field type="text"  name="country" className="form-control" placeholder="United States" readOnly />
                                </div>
                            </div>
                            <div className="form-group col-md-12">
                            <Select                               
                                isMulti
                                name="state"
                                value={this.state.userReleaseData.state}
                                options={this.state.states}
                                className="basic-multi-select form-control"
                                classNamePrefix="select state"
                                onChange={(data:any)=>{
                                   this.addSelectValue(data,setFieldValue)
                                }}
                            />
                            <ErrorMessage name="state" component="span" className="text-danger" />
                            </div>
                            <div className="form-group col-md-6">
                                <Field type="date" name="start_date" className="form-control" placeholder="Start Date" />
                                <ErrorMessage name="start_date" component="span" className="text-danger" />
                            </div>
                            <div className="form-group col-md-6">
                                <Field type="date" name="end_date" className="form-control" placeholder="End Date" />
                                <ErrorMessage name="end_date" component="span" className="text-danger" />
                            </div>
                            <div className="form-group col-md-6">
                                <Field as="select" className="form-control" name="bottle" placeholder="Select Sizes"
                                   onChange={(e: any) => {
                                    handleChange(e);
                                    this.setRegularPrice(e, setFieldValue);
                                 }}
                                >
                                    <option>Special Sizes</option>
                                    <option value="Cases">Cases</option>
                                    <option value="Bottle">Bottle</option>
                                </Field> 
                                <ErrorMessage name="bottle" component="span" className="text-danger" />
                            </div>
                            <div className="form-group col-md-6">
                                <Field type="text" name="size" className="form-control" placeholder="Minimum Required Quantity" />
                                <ErrorMessage name="size" component="span" className="text-danger" />
                            </div>
                            <div className="form-group col-md-6">
                                <Field type="text" name="regular_price" className="form-control" placeholder="$ Regular Price" readOnly/>
                                <ErrorMessage name="regular_price" component="span" className="text-danger" />
                            </div>
                            <div className="form-group col-md-6">
                                <Field type="text" name="special_price" className="form-control" placeholder="$ Special Price" 
                                  onKeyUp={(e: any) => {
                                    handleChange(e);
                                    this.calculateDiscount(values, setFieldValue);
                                  }}
                                />
                                <ErrorMessage name="special_price" component="span" className="text-danger" />
                            </div>
                            <div className="form-group col-md-6">
                                <Field type="text" name="discount" className="form-control" placeholder="Discount" readOnly/>
                                <ErrorMessage name="discount" component="span" className="text-danger" />
                            </div>
                            <div className="form-group col-md-6">
                                <Field as="select" className="form-control" name="status" placeholder="Status">
                                    <option>Status</option>
                                    <option value="active">Active</option>
                                    <option value="inactive">Inactive</option>
                                </Field> 
                                <ErrorMessage name="status" component="span" className="text-danger" />
                            </div>
                            <div className="form-group col-md-12 mt-4">
                                <button type="submit" className="btn btn-primary text-uppercase">Save</button>
                            </div>
                        </div>
                    </Form>
                    )}
                  </Formik>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state:any) => {
    return { edit_special_id:state.edit_special_id };
};

const mapDispatchToProps = (dispatch:any) => ({
    setApiLoader: () => dispatch(setApiLoader({}))
});

export default connect(mapStateToProps, mapDispatchToProps)(EditSpecial);

interface IProps {
    history:History;
    setApiLoader():void;
    edit_special_id:string;
}
  
interface IState {
     showAlert : boolean;
     variant : string;
     message : string;
     brand:string;
     userReleaseData : responseData;
     states : Array<state_details>;
     brand_images_array : any;
     portfolio_product:product;
}

interface state_details{
    label: string;
    value: number;
}

interface productForm {
    category:string;
    name:string;
    brand:string;
    description:string;
    country:string;
    state: Array<state_details>;
    start_date:string;
    end_date:string;
    bottle:string;
    regular_price:string;
    special_price:string;
    image_1:any;
    size:string;
    discount : string;
    status : string;
}

interface responseData {
    category:string;
    name:string;
    brand:string;
    description:string;
    country:string;
    state: Array<state_details>;
    start_date:string;
    end_date:string;
    bottle:string;
    regular_price:string;
    special_price:string;
    image_1:any;
    size:string;
    discount : string;
    status : string;
    main_image : string;
    category_number : string;
}

interface product {
    id:string;
    name: string;
    image_1: string;
    image_2:string;
    quantity: number;
    bottle_price: string;
    case_price: string;
    brand: string;
  }