import React from "react";
import { connect } from 'react-redux';
// import { Formik, Form, Field, ErrorMessage } from "formik";
// import { Alert } from 'react-bootstrap';
// import * as Yup from "yup";
import axios from "axios";
import constants from "../constants";
import { setApiLoader } from  '../redux/actions/distributor';
// @ts-ignore
import USAMap from "react-usa-map";

class Coverage extends React.Component<IProps,IState>{
    constructor(props:IProps){
        super(props);
        this.state = {
            states : []
        }
    }
    mapHandler = (event:any) => {
       // alert(event.target.dataset.name);
    };
    /* optional customization of filling per state and calling custom callbacks per state */
    statesCustomConfig = () => {
        const selected_states:any = {};
        this.state.states.forEach((res:states)=>{
            selected_states[res.state_code] = {
                fill : '#007bff',
                //clickHandler: (event:any) => console.log('Custom handler for NJ', event.target.dataset)
            }
        });
        return selected_states;
    };
    getStates = async() => {
        try{
          this.props.setApiLoader(); //set Api loader
          let url: string = `${constants.BASE_API_URL}protfolio/get_states`;
          const response = await axios.get(url, {
            headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
          });
          this.props.setApiLoader(); //set Api loader
          this.setState({
            states : response.data.data
          },()=>{
              this.getSelectedStates();
          });
        } catch (error) {
          this.props.setApiLoader(); //set Api loader
        }
    }

    getSelectedStates = async() => {
        try{
          this.props.setApiLoader(); //set Api loader
          let url: string = `${constants.BASE_API_URL}protfolio/get_selected_states`;
          const response = await axios.get(url, {
            headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
          });
          this.props.setApiLoader(); //set Api loader
          const selected_states:Array<string> = [];
          response.data.data.states.forEach((data:{state:string})=>{
            let states:Array<string> = data.state.split(',');
            selected_states.push(...states);
          });
          let filter_state:Array<states> = this.state.states.filter((res:states) => {
            return selected_states.indexOf(res.state_id.toString()) !== -1;
          });
          filter_state = filter_state.map((res:states)=>{
              if(res.state_code === 'DC'){
                  return {
                    country_code: res.country_code,
                    country_id: res.country_id,
                    state_code: "DC2",
                    state_id: res.state_id,
                    state_name: res.state_name,
                    status: res.status

                  }
              }
              return res;            
          });
          //console.log(filter_state)
          this.setState({
              states:filter_state
          });
        } catch (error) {
          this.props.setApiLoader(); //set Api loader
        }
    }



    componentDidMount = () =>{
        this.getStates();//get state of the Usa country
    }
    render(){
        return(
            <div className="buuz_right_outer">
                <div className="buuz_inner_heading">
                    <h1>Distributor Coverage Map</h1>
                </div>
                <div className="distributor_covarage_wrapper">
                    <div className="row">
                        <div className="col-md-9">
                            <div className="covarage_map text-center border">
                                {/* <h4>Distributor Coverage <br /> Map Here</h4> */}
                                <USAMap customize={this.statesCustomConfig()} onClick={this.mapHandler} />
                            </div>
                        </div>
                        <div className="col-md-3">
                            <ul className="coverage_location">
                                {this.state.states.map((data:states,index:number)=>(
                                    <li key={index}>{data.state_name}</li>
                                ))}
                                {/* <li>USA <i className="fa fa-trash-o text-danger"></i></li>
                                <li>USA <i className="fa fa-trash-o text-danger"></i></li> */}
                                <div className="text-right">
                                    <button type="submit" className="btn btn-primary mt-5">Save</button>
                                </div>
                            </ul>
                        </div>
                    </div>
                  </div>
               </div>
        )
    }
}

const mapStateToProps = (state:any) => {
    return { state };
};

const mapDispatchToProps = (dispatch:any) => ({
    setApiLoader: () => dispatch(setApiLoader({}))
});

export default connect(mapStateToProps, mapDispatchToProps)(Coverage);

interface IProps{
  setApiLoader():void;
}

interface IState{
    states : Array<states>;
}

interface states{
    country_code: string;
    country_id: number;
    state_code: string;
    state_id: number;
    state_name: string;
    status: string;
}