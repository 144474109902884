import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Alert } from 'react-bootstrap';
import * as Yup from "yup";
import axios from "axios";
import constants from "../constants";
import { History } from 'history';
import { Loader } from './partials/loader';
import { connect } from 'react-redux';
import { setApiLoader,setUserData } from  '../redux/actions/distributor';

class Login extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      error : false,
      variant : '',
      message : '',
      type : 'password',
      pass_img : 'fa fa-eye'
    }
  }

  login = async(data:{email:string,password:string}) => {
    try {
        this.props.setApiLoader(); //set Api loader
        let url:string = `${constants.BASE_API_URL}user/login`;
        const response = await axios.post(url,data);
        this.props.setApiLoader(); //set Api loader
        this.setState(prevState =>({
          error: !prevState.error,
          variant : 'success',
          message : response.data.message
        }));
        localStorage.setItem('token',response.data.data.token);
        let user_data = JSON.stringify(response.data.data.details);
        localStorage.setItem('user_data',user_data);
        localStorage.setItem('header_title','Distributor Profile');
        this.props.setUserData({user_data});
        this.props.history.push('/dashboard')
      } catch (err) {
        this.props.setApiLoader(); //set Api loader
        this.setState(prevState => ({
          error: !prevState.error,
          variant : 'danger',
          message :  err.response.data.message 
        }));

        setTimeout(()=>{
          this.setState(prevState =>({
            error : !prevState.error
          }))
        },4000);
      }
  }

  handleCLick=()=>{
    this.props.history.push('/signup');
  }

  changePasswordType =()=>{
    const type = (this.state.type === 'password') ? 'text' : 'password';
    const pass_img = (this.state.pass_img === 'fa fa-eye') ? 'fa fa-eye-slash' : 'fa fa-eye';
    this.setState({type,pass_img});
  }

  render() {
    const validate = Yup.object({
      email: Yup.string()
        .email("Email is invalid.")
        .required("Email is required."),
      password: Yup.string().required("Password is required."),
    });
    const initialValues: MyFormValues = {
      email: "",
      password: "",
      remmember_me: "",
    };
    return (
      <div className="buuz_login_wrapper">
        <div className="buzz_login_outer">
          <div className="text-center">
            {/* <img className="form_logo" src="/images/logo.png" alt="" /> */}
          </div>
          { this.props.requestApiLoader  && <Loader /> }
          <div className="buuz_login_block">
            <h1>Distributor Portal</h1>
            {this.state.error && <Alert variant={this.state.variant}>{this.state.message}</Alert> }
            <Formik
              initialValues={initialValues}
              validationSchema={validate}
              onSubmit={(values, actions) => {
                this.login(values);
              }}
            >
              <Form>
                <div className="form-group">
                  <Field
                    type="email"
                    name="email"
                    className="form-control"
                    placeholder="Email"
                  />
                  <div className="form_control_icon">
                    <img src="/images/email.png" alt="" />
                  </div>
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="text-danger"
                  />
                </div>
                <div className="form-group">
                  <Field
                    type={this.state.type}
                    name="password"
                    className="form-control"
                    placeholder="Password"
                  />
                  <div className="form_control_icon">
                    <img src="/images/password.png" alt="" />
                  </div>
                  <div className="view_password_icon">
	                  <span><i className={this.state.pass_img} aria-hidden="true" onClick={this.changePasswordType}></i></span>
                  </div>
                  <ErrorMessage
                    name="password"
                    component="div"
                    className="text-danger"
                  />
                </div>
                <div className="forgot_password">
                  <div className="custom-control custom-checkbox">
                    <Field
                      type="checkbox"
                      name="remmember_me"
                      className="custom-control-input"
                      id="customControlValidation1"
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="customControlValidation1"
                    >
                      Remember Me
                    </label>
                  </div>
                  <a onClick={()=>this.props.history.push('/forget_password')}>Forgot Password?</a>
                </div>
                <div className="login_btn_wrapper">
                  <button type="button" className="btn btn-primary" onClick={this.handleCLick}>
                    <img src="/images/distribution_profile_off.png" alt="" /> Sign Up
                  </button>
                  <button type="submit" className="btn btn-primary">
                    <img src="/images/login.png" alt="" /> Log In
                  </button>
                </div>
              </Form>
            </Formik>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({requestApiLoader=false,user_data=''}) => {
  return { requestApiLoader, user_data };
};

const mapDispatchToProps = (dispatch:any) => ({
  setApiLoader: () => dispatch(setApiLoader({})),
  setUserData:  (payload:{user_data:string}) => dispatch(setUserData(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);

interface MyFormValues {
  email: string;
  password: string;
  remmember_me: string;
}

interface IProps {
  history:History;
  setApiLoader():void;
  requestApiLoader:boolean;
  setUserData(payload:{user_data:string}):void;
}

interface IState {
  error : boolean;
  variant : string;
  message : string;
  type : string;
  pass_img : string;
}
