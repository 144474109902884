import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import Header from '../components/partials/header';
import SideBar from '../components/partials/SideBar';

const PrivateRoute = (props: PrivateRouteProps) => {
  let { component: Component, ...rest } = props;
  return (
      <Route
          {...rest}
          render={(props) => {
              return localStorage.getItem('token') ? 
              <div className="buuz_main_wrapper">
                 <SideBar />
                 <div className="buuz_right_contant">
                   <Header />
                   <Component {...props} /> 
                 </div>
              </div>              
              : <Redirect to="/" />;
          }}
      />
  );
};

export default PrivateRoute;

interface PrivateRouteProps extends RouteProps {
    component: React.ComponentType<any>;
}